import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {generateQuestionName} from "../PreviewUtils";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles({
	field: {
		width: "30%",
		boxSizing: "border-box",
		margin: 0,
		marginRight: "3%",
		display: "inline-block"
	}
});

export default function TagCloudPreview(props) {
	const classes = useStyles(props);
	const questionName = generateQuestionName(props);
	const fields = [];

	for (let i = 0; i < 3; i++)
	{
		let label = "Odpověď " + (i + 1);

		fields.push(
			<div className={classes.field} key={i}>
				<TextField label={label} variant="outlined" fullWidth={true} />
			</div>
		);
	}

	return (
		<React.Fragment>
			<h5>Question preview</h5>
			<h6>{questionName}</h6>
			{fields}
		</React.Fragment>
	);
}