import {Box, Button} from "@material-ui/core";
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import {Alert} from "@material-ui/lab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {commonUtils_GetApiCallOptions, commonUtils_GetApiEndpoint} from "../utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";

const useStyles = makeStyles({
	alert: {
		marginBottom: 32,
		borderRadius: 24
	}
});

export default function PasswordRecoveryRequestForm() {
	const classes = useStyles();

	const [email, setEmail] = useState("");
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("info");

	function handleSubmit(event)
	{
		event.preventDefault();

		if (email)
		{
			layoutUtils_LoadingStart();

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_PASSWORD_RECOVERY_REQUEST),
				commonUtils_GetApiCallOptions({e: email})
			)
			.then(blob => blob.json())
			.then(response => {
				if (response.statusCode === 200)
				{
					setAlert("Recovery link was sent to your e-mail address.");
					setAlertType("info");
				}
				else
				{
					setAlert("E-mail address was not found.");
					setAlertType("warning");
				}

				setEmail("");
				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
		}
	}

	return (
		<React.Fragment>
			{alert && <Alert severity={alertType} className={classes.alert}>{alert}</Alert>}
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<div>
					<TextField label="E-mail" type="email" value={email} onChange={event => setEmail(event.target.value)} autoFocus variant="outlined" fullWidth={true}/>
					<Box style={{marginTop: 32, marginBottom: 32}}>
						<Button variant="contained" color="primary" size="large" type="submit">
							Reset password
						</Button>
					</Box>
					<Box>
						<Link href="/sign-in" color="inherit">
							Back to sign in
						</Link>
					</Box>
				</div>
			</form>
		</React.Fragment>
	);
}