import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Switch from "@material-ui/core/Switch";
import QuestionPreviewModal from "../question_preview/QuestionPreviewModal";
import ResultPreviewModal from "../chart_preview/ResultPreviewModal";
import {commonUtils_NumberFormat} from "../utils/common.utils";
import {layoutUtils_ModalClosed, layoutUtils_ModalOpened, layoutUtils_SetModalHeight} from "../utils/layout.utils";

const useStyles = makeStyles({
	root: props => ({
		padding: "16px 32px",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: "100%",
		boxSizing: "border-box",
		float: "left",
		lineHeight: 2,
		marginTop: 16,
		"& .check": {
			width: 70,
			float: "left",
			marginTop: 5
		},
		"& .question": {
			width: 855,
			float: "left",
			font: "normal normal normal 16px/25px 'Montserrat'",
			letterSpacing: 0,
			color: "#000000",
			lineHeight: 3
		},
		"& .tools": {
			width: 100,
			float: "left",
			paddingTop: 10,
			"& a": {
				display: "inline-block",
				width: 24,
				height: 24,
				marginRight: 24
			},
			"& .question-preview": {
				background: "transparent url('/icon/message.png') 0% 0% no-repeat padding-box"
			},
			"& .result-preview": {
				background: "transparent url('/icon/graph.png') 0% 0% no-repeat padding-box"
			},
			"& a.last": {
				marginRight: 0
			}
		},
		"& .price": {
			borderLeft: "1px solid #DBE5EE",
			paddingLeft: 0,
			float: "left",
			width: 110,
			boxSizing: "border-box",
			"& h6": {
				font: "normal normal normal 14px/18px 'Montserrat'",
				letterSpacing: 0,
				color: "#5C7692",
				margin: 0,
				lineHeight: 3,
				textAlign: "right"
			}
		}
	}),
});

export default function BlockAdditionalQuestion(props) {
	const classes = useStyles(props);

	const [openQuestionPreview, setOpenQuestionPreview] = useState(false);
	const [openResultPreview, setOpenResultPreview] = useState(false);

	const checked = props.research.selectedQuestionPatternIds.value.indexOf(props.question.id.value) > -1;

	const paneluids = props.research.paneluids.value;
	const price = props.research.addQuestionPriceLevels.value[paneluids];

	const handleOpenQuestionPreview = (e) => {
		e.preventDefault();
		setOpenQuestionPreview(true);
		layoutUtils_ModalOpened();
	};

	const handleCloseQuestionPreview = (e) => {
		e.preventDefault();
		setOpenQuestionPreview(false);
		layoutUtils_ModalClosed();
	};

	const handleOpenResultPreview = (e) => {
		e.preventDefault();
		setOpenResultPreview(true);

		setTimeout(function (){
			layoutUtils_SetModalHeight();
			layoutUtils_ModalOpened();
		}, 500);
	};

	const handleCloseResultPreview = (e) => {
		e.preventDefault();
		setOpenResultPreview(false);
		layoutUtils_ModalClosed();
	};

	return (
		<Card className={classes.root}>
			<div className="check">
				<Switch
					color="primary"
					checked={checked}
					onChange={(e) => props.handleAddQuestionSelectionChange(e, props.question.id.value)}/>
			</div>
			<div className="question">
				{props.question.orderTitle.value}
			</div>
			<div className="tools">
				<a href="#" className="question-preview" onClick={e => handleOpenQuestionPreview(e)}> </a>
				<a href="#" className="result-preview last" onClick={e => handleOpenResultPreview(e)}> </a>
			</div>
			<div className="price">
				<h6>{commonUtils_NumberFormat(price)} {props.research.currency.value}</h6>
			</div>

			<QuestionPreviewModal
				research={props.research}
				question={props.question}
				open={openQuestionPreview}
				handleOpen={handleOpenQuestionPreview}
				handleClose={handleCloseQuestionPreview}/>

			<ResultPreviewModal
				research={props.research}
				question={props.question}
				open={openResultPreview}
				handleOpen={handleOpenResultPreview}
				handleClose={handleCloseResultPreview}/>
		</Card>
	);
}