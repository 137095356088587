import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {commonUtils_LoadData, commonUtils_Ping, commonUtils_SetTopMargin} from "./utils/common.utils";
import {Alert} from "@material-ui/lab";
import {
    layoutUtils_SetupDynamicHeaderSize,
    layoutUtils_SetupDynamicModalHeight
} from "./utils/layout.utils";

commonUtils_Ping(function (){
    commonUtils_LoadData(function(response){
        const el = document.getElementById('root');

        if (response.statusCode !== 403)
        {
            ReactDOM.render(<App />, el);
            layoutUtils_SetupDynamicHeaderSize();
            layoutUtils_SetupDynamicModalHeight();
        }
        else
        {
            ReactDOM.render(<Alert severity="warning">{response.data.detail} Go to <a href="/">dashboard</a>.</Alert>, el);
        }
    });
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
