import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Menu, MenuItem} from "@material-ui/core";
import {commonUtils_RedirectTo} from "../utils/common.utils";

const useStyles = makeStyles({
	root: {
		"& ul": {
			listStyle: "none",
			margin: 0,
			padding: 0,
			"& li": {
				display: "inline-block",
				margin: 0,
				padding: 0,
				marginLeft: 24
			}
		},
		position: "absolute",
		top: 24,
		right: 40,
		"& .simple-menu": {
			position: "absolute",
			right: 20,
			top: 50
		}
	},
	notification: {
		background: "transparent url('/icon/notification.png') 0% 0% no-repeat padding-box", width: 22, height: 22, display: "block"
	},
	settings: {
		background: "transparent url('/icon/settings.png') 0% 0% no-repeat padding-box", width: 22, height: 22, display: "block"
	},
	login: {
		background: "transparent url('/icon/login.png') 0% 0% no-repeat padding-box", width: 22, height: 22, display: "block"
	},
});

export default function HeaderToolbox() {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event, target) => {
		setAnchorEl(null);

		if (target && target !== 'backdropClick')
		{
			commonUtils_RedirectTo(target);
		}
	};

	return (
		<div className={classes.root + " not-printable"}>
			<ul>
				{/*<li><a href="#" className={classes.notification}> </a></li>*/}
				<li><a href="#" className={classes.settings} onClick={handleClick}> </a></li>
				<li><a href="/sign-out" className={classes.login}> </a></li>
			</ul>
			<Menu className="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={e => handleClose(e, '/?codes')}>Measurement</MenuItem>
				<MenuItem onClick={e => handleClose(e, '/profile')}>My account</MenuItem>
			</Menu>
		</div>
	);
}