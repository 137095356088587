import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import {Paper} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles({
	root: props => ({
		padding: 0,
		paddingTop: 4,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: 768,

		boxSizing: "border-box",
		float: "left",
		marginBottom: 48
	}),
});

export default function BlockVideo(props) {
	const classes = useStyles(props);

	const items = [
		{img: "/carousel/1.jpg"}, {img: "/carousel/2.jpg"}, {img: "/carousel/3.jpg"}, {img: "/carousel/4.jpg"},
		{img: "/carousel/5.jpg"}, {img: "/carousel/6.jpg"}, {img: "/carousel/7.jpg"}, {img: "/carousel/8.jpg"}
	];

	useEffect(() => {
		//preloading image
		items.forEach((item) => {
			const img = new Image();
			img.src = item.img;
		});
	}, []);

	return (
		<Card className={classes.root}>
			<Carousel
				animation="slide"
				autoPlay={true}
				NextIcon={<img src="/icon/circle-right.svg" style={{opacity: 0.3}}/>}
				PrevIcon={<img src="/icon/circle-left.svg" style={{opacity: 0.3}}/>}
				navButtonsProps={{style:{backgroundColor: "transparent"}}}
				indicatorContainerProps={{style: {position:"absolute", bottom: 8}}}
				cycleNavigation={true}
				timeout={700}
			>
				{
					items.map( (item, i) => <Item key={i} item={item} /> )
				}
			</Carousel>
		</Card>
	);
}

function Item(props)
{
	return (
		<Paper style={{margin: "0 auto", boxShadow: "none", textAlign: "center"}}>
			<img src={props.item.img} style={{width: 760, height: 513, borderRadius: 21}}/>
		</Paper>
	)
}