import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import AuthHeader from "../header/AuthHeader";
import SignInForm from "./SignInForm";
import PasswordRecoveryRequestForm from "./PasswordRecoveryRequestForm";
import SignUpForm from "./SignUpForm";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PasswordRecoveryForm from "./PasswordRecoveryForm";
import AccountActivationForm from "./AccountActivationForm";
import {commonUtils_RedirectTo} from "../utils/common.utils";

const pages = {
	"/sign-in": {"component": SignInForm, "h1": "Sign In"},
	"/sign-up": {"component": SignUpForm, "h1": "Sign Up"},
	"/sign-up/success": {"component": SignUpForm, "h1": "Sign Up Success"},
	"/password-recovery-request": {"component": PasswordRecoveryRequestForm, "h1": "Password recovery"},
	"/password-recovery": {"component": PasswordRecoveryForm, "h1": "Password recovery"},
	"/account-activation": {"component": AccountActivationForm, "h1": "Account activation"}
};

const useStyles = makeStyles({
	root: {
		width: 320,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		padding: "38px 40px",
		marginLeft: 80,
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)"
	},
	ill: {
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)"
	}
});

function getForm() {
	const path = document.location.pathname;

	return pages[path] || SignInForm;
}

export default function SignPage(props) {
	if (props.out)
	{
		localStorage.removeItem('u');
		commonUtils_RedirectTo('/sign-in?signed_out');
	}

	const form = getForm();
	const FormComponent = form.component;
	const classes = useStyles();

	return (
		<Grid container spacing={3}>
			<Grid item xs={6}>
				<Card className={classes.root}>
					<AuthHeader content={form.h1}/>
					<FormComponent/>
				</Card>
			</Grid>
			<Grid item xs={6}>
				<img src="/illustration@2x.png" className={classes.ill} width="518" alt="Web analytics illustration"/>
			</Grid>
		</Grid>
	);
}
