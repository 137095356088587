import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import AuthHeader from "../header/AuthHeader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Alert} from "@material-ui/lab";
import {Button, CircularProgress} from "@material-ui/core";
import {commonUtils_GetApiCallOptions, commonUtils_GetApiEndpoint} from "../utils/common.utils";

const useStyles = makeStyles({
	root: {
		width: 320,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		padding: "38px 40px",
		marginLeft: 80,
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)"
	},
	ill: {
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)"
	},
	alert: {
		marginBottom: 24
	}
});

export default function UnsubscribePage(props) {
	const classes = useStyles();
	const url = new URL(document.location.href);

	const [hash, setHash] = useState(url.searchParams.has("h") ? url.searchParams.get("h") : "");
	const [email, setEmail] = useState("");
	const [success, setSuccess] = useState(false);
	const [subscribe, setSubscribe] = useState(false);
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const [requests, setRequests] = useState(0);

	function resetRequests()
	{
		setRequests(0);
		setSubscribe(false);
		setSuccess(false);
		setError(false);
	}

	function unsub()
	{
		if (!hash) return;

		if (!requests)
		{
			setRequests(requests => ++requests);

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_EMAIL_UNSUBSCRIBE),
				commonUtils_GetApiCallOptions({h: hash})
			)
				.then(blob => blob.json())
				.then(response => {
					if (response.statusCode === 200) {
						setEmail(response.data.email);
						setSuccess(true);
						setSubscribe(false);
						setError(false);
						setErrorMsg("");
					} else if (response.statusCode < 500) {
						setSuccess(false);
						setError(true);
						setErrorMsg(response.data.detail);
					} else {
						setSuccess(false);
						setError(true);
						setErrorMsg("An error occurred. Please, try again later.");
						console.log(response.data.detail);
						console.log(response.statusMessage);
					}
				})
				.catch(e => {
					console.log(e);
					return e;
				});
		}
	};

	function sub()
	{
		if (!hash) return;

		fetch(
			commonUtils_GetApiEndpoint(process.env.REACT_APP_API_EMAIL_SUBSCRIBE),
			commonUtils_GetApiCallOptions({h: hash})
		)
			.then(blob => blob.json())
			.then(response => {
				if (response.statusCode === 200)
				{
					setEmail(response.data.email);
					setSuccess(true);
					setSubscribe(true);
					setError(false);
					setErrorMsg("");
				}
				else if (response.statusCode < 500)
				{
					setSuccess(false);
					setError(true);
					setErrorMsg(response.data.detail);
				}
				else
				{
					setSuccess(false);
					setError(true);
					setErrorMsg("An error occurred. Please, try again later.");
					console.log(response.data.detail);
					console.log(response.statusMessage);
				}
			})
			.catch(e => {
				console.log(e);
				return e;
			});
	};

	setTimeout(function (){
		unsub();
	}, 1000);

	return (
		<Grid container spacing={3}>
			<Grid item xs={6}>
				<Card className={classes.root}>
					<AuthHeader content="Unsubscribe from automated emails"/>
					{!subscribe && success &&
					<Alert severity="success" className={classes.alert}>
						Successfully unsubscribed. We will no longer send automated emails to {email}.
					</Alert>}
					{!subscribe && success &&
					<Alert severity="info" className={classes.alert}>
						You will still receive emails like order confirmations and password recovery messages.
					</Alert>}
					{!subscribe && !success && hash && !error &&
					<Alert severity="info" className={classes.alert} icon=" ">
						<CircularProgress size={12} />&nbsp;&nbsp;Unsubscribing in progress
					</Alert>}
					{subscribe && success &&
					<Alert severity="success" className={classes.alert}>
						Successfully subscribed. You will receive all our emails.
					</Alert>}
					{!hash &&
					<Alert severity="warning" className={classes.alert}>
						Bad request, no e-mail address specified.
					</Alert>}
					{error &&
					<Alert severity="warning" className={classes.alert}>
						{errorMsg}
					</Alert>}
					{!subscribe && success &&
					<Button variant="contained" color="primary" size="large" type="button" onClick={sub}>
						Back to mailing list
					</Button>}
					{subscribe && success &&
					<Button variant="contained" color="primary" size="large" type="button" onClick={resetRequests}>
						Unsubscribe
					</Button>}
				</Card>
			</Grid>
			<Grid item xs={6}>
				<img src="illustration@2x.png" className={classes.ill} width="518" alt="Web analytics illustration"/>
			</Grid>
		</Grid>
	);
}
