import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import QuestionPreviewModal from "../question_preview/QuestionPreviewModal";
import ResultPreviewModal from "../chart_preview/ResultPreviewModal";
import {
	commonUtils_CheckAuthorized,
	commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint,
	commonUtils_GetLocalization,
	commonUtils_GetUser,
	commonUtils_MeasurementUrl,
	commonUtils_NumberFormat,
	commonUtils_RedirectTo,
	commonUtils_SetData
} from "../utils/common.utils";
import {
	layoutUtils_LoadingEnd,
	layoutUtils_LoadingStart,
	layoutUtils_ModalClosed,
	layoutUtils_ModalOpened,
	layoutUtils_SetModalHeight
} from "../utils/layout.utils";

const useStyles = makeStyles({
	root: props => ({
		padding: "16px 32px",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: "100%",
		boxSizing: "border-box",
		float: "left",
		lineHeight: 1,
		marginTop: 16,
		"& .question": {
			width: 925,
			float: "left",
			font: "normal normal normal 16px/25px 'Montserrat'",
			letterSpacing: 0,
			color: "#000000"
		},
		"& .tools": {
			width: 100,
			float: "left",
			"& a": {
				display: "inline-block",
				width: 24,
				height: 24,
				marginRight: 24
			},
			"& .question-preview": {
				background: "transparent url('/icon/message.png') 0% 0% no-repeat padding-box"
			},
			"& .result-preview": {
				background: "transparent url('/icon/graph.png') 0% 0% no-repeat padding-box"
			},
			"& a.last": {
				marginRight: 0
			}
		},
		"& .price": {
			borderLeft: "1px solid #DBE5EE",
			paddingLeft: 0,
			float: "left",
			width: 110,
			boxSizing: "border-box",
			"& h6": {
				font: "normal normal normal 14px/18px 'Montserrat'",
				letterSpacing: 0,
				color: "#5C7692",
				margin: 0,
				lineHeight: 2,
				textAlign: "right"
			}
		},
		"& .question-form": {
			width: "100%",
			borderTop: "1px solid #DBE5EE",
			float: "left",
			marginTop: 24,
			paddingTop: 24,
			"& .form-control": {
				marginRight: 24
			}
		},
	}),
});

export default function BlockQuestion(props) {
	const classes = useStyles(props);

	const [isAmountMinBoundValid, setIsAmountMinBoundValid] = useState(isMinAmountValid(props.research.amountLevelMin.value, props.research.amountLevelMax.value));
	const [isAmountMaxBoundValid, setIsAmountMaxBoundValid] = useState(isMaxAmountValid(props.research.amountLevelMin.value, props.research.amountLevelMax.value));
	const [openQuestionPreview, setOpenQuestionPreview] = useState(false);
	const [openResultPreview, setOpenResultPreview] = useState(false);

	const paneluids = props.research.paneluids.value;
	const price = props.research.numRespondentsBasePriceLevels.value[paneluids];
	const user = commonUtils_GetUser();
	const localization = commonUtils_GetLocalization();

	function handleMinimalAmountChange(event)
	{
		if (validateAmountBounds())
		{
			saveAmountBound(event.target.value, 'amount_level_min');
			props.research.amountLevelMin.value = event.target.value;
		}
	}

	function handleMaximalAmountChange(event)
	{
		if (validateAmountBounds())
		{
			saveAmountBound(event.target.value, 'amount_level_max');
			props.research.amountLevelMax.value = event.target.value;
		}
	}

	function saveAmountBound(value, what)
	{
		props.setIsLoading(true);
		layoutUtils_LoadingStart();

		fetch(
			commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_SET),
			commonUtils_GetApiCallOptions({
				uid: user.id,
				token: user.token,
				co: localization.country,
				cid: props.client.id,
				mid: props.measurement.id,
				rid: props.research.id.value,
				key: what,
				value: value
			})
		)
		.then(blob => blob.json())
		.then(response => {
			if (commonUtils_CheckAuthorized(response))
			{
				if (response.statusCode === 200)
				{
					commonUtils_SetData(response.data);
				}
				else if (response.statusCode === 406 && response.statusMessage === 'LACK_OF_RESPONDENTS')
				{
					commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.mid));
				}
			}

			props.setIsLoading(false);
			layoutUtils_LoadingEnd();
		})
		.catch(e => {
			console.log(e);
			props.setIsLoading(false);
			layoutUtils_LoadingEnd();
			return e;
		});
	}

	function validateAmountBounds()
	{
		let min = parseInt(document.getElementById("amountBoundMin").value);
		let max = parseInt(document.getElementById("amountBoundMax").value);

		let minIsValid = isMinAmountValid(min, max);
		let maxIsValid = isMaxAmountValid(min, max);

		setIsAmountMinBoundValid(minIsValid);
		setIsAmountMaxBoundValid(maxIsValid);

		return minIsValid && maxIsValid;
	}

	function isMinAmountValid(min, max)
	{
		return isNaN(min) ? false : (min > 0 ? (max / min >= 10) : false);
	}

	function isMaxAmountValid(min, max)
	{
		return isNaN(max) ? false : (max > min ? (max / min >= 10) : false);
	}

	const handleOpenQuestionPreview = (e) => {
		e.preventDefault();
		setOpenQuestionPreview(true);
		layoutUtils_ModalOpened();
	};

	const handleCloseQuestionPreview = (e) => {
		e.preventDefault();
		setOpenQuestionPreview(false);
		layoutUtils_ModalClosed();
	};

	const handleOpenResultPreview = (e) => {
		e.preventDefault();
		setOpenResultPreview(true);

		setTimeout(function (){
			layoutUtils_SetModalHeight();
			layoutUtils_ModalOpened();
		}, 500);
	};

	const handleCloseResultPreview = (e) => {
		e.preventDefault();
		setOpenResultPreview(false);
		layoutUtils_ModalClosed();
	};

	return (
		<Card className={classes.root}>
			<div className="question">
				{props.question.orderTitle.value}
			</div>
			<div className="tools">
				<a href="#" className="question-preview" onClick={e => handleOpenQuestionPreview(e)}> </a>
				<a href="#" className="result-preview last" onClick={e => handleOpenResultPreview(e)}> </a>
			</div>
			<div className="price">
				<h6>{commonUtils_NumberFormat(price)} {props.research.currency.value}</h6>
			</div>
			{props.question.settings.value.IsPriceLevels &&
			<div className="question-form">
				<TextField
					type="text" size="small" variant="outlined" label="Minimum amount" className="form-control" id="amountBoundMin"
					defaultValue={props.research.amountLevelMin.value}
					onChange={validateAmountBounds}
					onBlur={e => handleMinimalAmountChange(e)}
					error={!isAmountMinBoundValid}
					helperText={isAmountMinBoundValid ? '' : 'Minimum amount has to be a number that is at least 10 times lower than the maximum amount.'}
				/>
				<TextField
					type="text" size="small" variant="outlined" label="Maximum amount" className="form-control" id="amountBoundMax"
					defaultValue={props.research.amountLevelMax.value}
					onChange={validateAmountBounds}
					onBlur={e => handleMaximalAmountChange(e)}
					error={!isAmountMaxBoundValid}
					helperText={isAmountMaxBoundValid ? '' : 'Maximum amount has to be a number that is at least 10 times the minimum amount.'}
				/>
			</div>
			}

			<QuestionPreviewModal
				research={props.research}
				question={props.question}
				open={openQuestionPreview}
				handleOpen={handleOpenQuestionPreview}
				handleClose={handleCloseQuestionPreview}/>

			<ResultPreviewModal
				research={props.research}
				question={props.question}
				open={openResultPreview}
				handleOpen={handleOpenResultPreview}
				handleClose={handleCloseResultPreview}/>
		</Card>
	);
}