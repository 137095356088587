import {Box, Button, FormControlLabel, FormHelperText} from "@material-ui/core";
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
	commonUtils_FireWixTracker,
	commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint,
	commonUtils_RedirectTo
} from "../utils/common.utils";
import {Alert} from "@material-ui/lab";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";
import CustomCheckbox from "../customElements/CustomCheckbox";

const useStyles = makeStyles({
	textField: {
		marginBottom: 30,
		borderRadius: 8
	},
	btnBox: {
		marginTop: 32,
		marginBottom: 32
	},
	alert: {
		marginBottom: 32,
		borderRadius: 24
	},
	tc: {
		"& a": {
			color: "#00448D",
			textDecoration: "none",
		},
		"& a:hover": {
			textDecoration: "underline"
		}
	},
});

export default function SignUpForm(props)
{
	const classes = useStyles();

	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [tc, setTc] = useState(false);
	const [error, setError] = useState("");

	const [isNameError, setIsNameError] = useState(false);
	const [isEmailError, setIsEmailError] = useState(false);
	const [emailError, setEmailError] = useState("E-mail address is not valid");
	const [isPhoneError, setIsPhoneError] = useState(false);
	const [isTcError, setIsTcError] = useState(false);

	const isOk = !(document.location.href.match(/success/) === null);

	function handleSubmit(event)
	{
		event.preventDefault();

		if (name && email && phone && tc)
		{
			layoutUtils_LoadingStart();

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_SIGN_UP),
				commonUtils_GetApiCallOptions({e: email, n: name, p: phone})
			)
			.then(blob => blob.json())
			.then(response => {
				if (response.statusCode === 200)
				{
					commonUtils_FireWixTracker(name, email, phone, ["H24Registered"], function (){
						setTimeout(function (){
							commonUtils_RedirectTo('/sign-up/success');
							layoutUtils_LoadingEnd();
						}, 2000)
					});
				}
				else if (response.statusCode === 406)
				{
					setIsEmailError(true);
					setEmailError(response.data.detail);
					layoutUtils_LoadingEnd();
				}
				else
				{
					setError("Sign Up failed");
					console.log(response.statusMessage);
					layoutUtils_LoadingEnd();
				}
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
		}
		else
		{
			if (!name) setIsNameError(true);
			if (!phone) setIsPhoneError(true);
			if (!email) setIsEmailError(true);
			if (!tc) setIsTcError(true);
		}
	}

	function changeName(e)
	{
		setName(e.target.value);

		if (e.target.value.trim().length)
		{
			setIsNameError(false);
		}
	}

	function changePhone(e)
	{
		setPhone(e.target.value);

		if (e.target.value.trim().length)
		{
			setIsPhoneError(false);
		}
	}

	function changeEmail(e)
	{
		setEmail(e.target.value);

		if (e.target.value.trim().length)
		{
			setIsEmailError(false);
		}
	}

	function changeTc(e)
	{
		if (!tc)
		{
			setIsTcError(false);
		}
		setTc(!tc);
	}

	return (
		<React.Fragment>
			{error && <Alert severity="warning" className={classes.alert}>{error}</Alert>}
			{isOk && <Alert severity="success" className={classes.alert}>Registration successful, check your mailbox and follow the instruction in the message from us.</Alert>}
			{!isOk && <form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<div>
					<TextField label="Name" type="text" error={isNameError} helperText={isNameError ? "Name is required" : ""} value={name} onChange={changeName} autoFocus className={classes.textField} variant="outlined" fullWidth={true}/>
					<TextField label="E-mail" type="email" error={isEmailError} helperText={isEmailError ? emailError : ""} value={email} onChange={changeEmail} className={classes.textField} variant="outlined" fullWidth={true}/>
					<TextField label="Phone" type="text" error={isPhoneError} helperText={isPhoneError ? "Phone is required" : ""} value={phone} onChange={changePhone} className={classes.textField} variant="outlined" fullWidth={true}/>
					<FormControlLabel control={<CustomCheckbox checked={tc} onChange={changeTc}/>} label={<span className={classes.tc}>Accept <a target="_blank" href="/h24-tc.pdf">terms and conditions</a></span>}/>
					{isTcError && <FormHelperText error={isTcError} children="Accept terms and conditions"/>}

					<Box style={{marginTop: 30}}>
						<Button variant="contained" color="primary" size="large" type="submit">
							Sign Up
						</Button>
					</Box>
				</div>
			</form>}
		</React.Fragment>
	);
}