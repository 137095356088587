import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import BlockLabels from "../BlockLabels";
import {Button} from "@material-ui/core";
import ProgressBar from "../ProgressBar";
import StatusIcon from "./StatusIcon";
import {
	commonUtils_FormatPercent,
	commonUtils_NewResearchUrl,
	commonUtils_PaymentUrl,
	commonUtils_RedirectTo,
	commonUtils_ResearchUrl
} from "../utils/common.utils";

const useStyles = makeStyles({
	root: props => ({
		width: 768,
		float: "left",
		boxSizing: "border-box",
		"& .card": {
			padding: 40,
			background: "#FFFFFF 0% 0% no-repeat padding-box",
			boxShadow: "0px 8px 24px #114E9114",
			borderRadius: 24,
			boxSizing: "border-box",
			marginBottom: 32,
			"& .col": {
				border: "none",
			},
			"& .col1": {
				width: 343,
				float: "left",
				marginRight: 32,
				borderRight: "1px solid #DBE5EE",
				"& .created": {
					font: "normal normal normal 11px/14px Montserrat",
					color: "#5C7692",
					margin: 0,
					padding: 0
				},
				"& h4": {
					font: "normal normal normal 16px/25px Montserrat",
					color: "#000000",
					margin: 0,
					marginTop: 16,
					padding: 0,
				},
				"& .btn-details": {
					background: "#DBE5EE 0% 0% no-repeat padding-box",
					boxShadow: "0px 0px 0px #022B5729",
					borderRadius: 8,
					font: "normal normal normal 13px/16px Montserrat",
					color: "#000000",
					textTransform: "uppercase",
					padding: "8px 16px",
					marginTop: 16
				},
				"& .btn-checkout": {
					background: "#0162CA 0% 0% no-repeat padding-box",
					boxShadow: "0px 0px 0px #022B5729",
					borderRadius: 8,
					font: "normal normal normal 13px/16px Montserrat",
					color: "#FFFFFF",
					textTransform: "uppercase",
					padding: "8px 16px",
					marginTop: 16
				}
			},
			"& .col2": {
				width: 309,
				float: "right",
				"& .header": {
					"& .count": {
						"& h1": {
							margin: 0,
							font: "normal normal bold 25px/35px Montserrat",
							letterSpacing: 0,
							color: "#000000"
						}
					}
				}
			}
		}

	}),
});

export default function BlockResearches(props) {
	const classes = useStyles(props);

	function goToOrder(event, rid)
	{
		event.preventDefault();
		commonUtils_RedirectTo(commonUtils_NewResearchUrl(props.measurement.id, rid));
	}

	function goToDetail(event, rid)
	{
		event.preventDefault();
		commonUtils_RedirectTo(commonUtils_ResearchUrl(props.measurement.id, rid));
	}

	function goToPayment(event, rid)
	{
		event.preventDefault();
		commonUtils_RedirectTo(commonUtils_PaymentUrl(props.measurement.id, rid));
	}

	const researches = [];

	for (let i in props.measurement.researches)
	{
		let r = props.measurement.researches[i].value;
		let type = props.measurement.researches[i].type === "App.Model.Research.ProductResearch" ? "Product research" : "Brand research";
		let progressFinished, progressLabel, progressColor, statusIcon, iconBg;

		switch (r.status.value)
		{
			case 'Requested':
				progressFinished = r.complete.value;
				progressLabel = "(due date " + r.dueDate.value.date.split(" ")[0] + ")";
				progressColor = "#FF9800";
				break;
			case 'Overdue':
				progressFinished = r.complete.value;
				progressLabel = "(due date " + r.dueDate.value.date.split(" ")[0] + ")";
				progressColor = "#FF5800";
				break;
			case 'In Progress':
				progressFinished = r.complete.value;
				progressLabel = commonUtils_FormatPercent(r.complete.value);
				progressColor = "#00ECEB";
				break;
			case 'Finished':
				progressFinished = r.complete.value;
				progressLabel = commonUtils_FormatPercent(r.complete.value);
				progressColor = "#00D789";
				break;
			case 'Draft':
			default:
				progressFinished = r.complete.value;
				progressLabel = "(needs checkout)";
				progressColor = "#5C7692";
		}

		if (props.finishedOnly && r.status.value !== 'Finished' && r.status.value !== 'In Progress')
		{
			continue;
		}

		researches.push(
			<Card className="card" key={i}>
				<div className="col col1">
					{r.status.value === "Draft" && <p className="created">Created - {r.created.value.date.split(" ")[0]}</p>}
					{r.status.value !== "Draft" && <p className="created">Created - {r.ordered.value.date.split(" ")[0]}</p>}
					<h4>{r.name.value}</h4>
					<BlockLabels text1={type} margin="0"/>
					{r.status.value === "Finished" &&
						<Button
							variant="contained"
							color="secondary"
							className="btn-details"
							onClick={event => goToDetail(event, r.id.value)}>Show details</Button>}
					{["Requested", "Overdue"].indexOf(r.status.value) > -1 &&
					<Button
						variant="contained"
						color="primary"
						className="btn-checkout"
						onClick={event => goToPayment(event, r.id.value)}>Payment info</Button>}
					{r.status.value === "Draft" &&
						<Button
							variant="contained"
							color="primary"
							className="btn-checkout"
							onClick={event => goToOrder(event, r.id.value)}>Checkout</Button>}
				</div>
				<div className="col col2">
					<div className="header">
						<StatusIcon status={r.status.value} marginRight={16}/>
						<div className="count">
							<span className="block-label">Status</span>
							<h1>{r.status.value}</h1>
						</div>
						<ProgressBar
							margin="16px 0 24px 0"
							color={progressColor}
							finished={progressFinished}
							label={progressLabel}/>
					</div>
				</div>
			</Card>
		);
	}

	return (
		<div className={classes.root}>
			{researches}
		</div>
	);
}