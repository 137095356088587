import {
    commonUtils_AddClass,
    commonUtils_HasClass,
    commonUtils_RemoveClass,
    commonUtils_SetTopMargin
} from "./common.utils";
import {useEffect} from "react";

export function layoutUtils_SetupDynamicHeaderSize()
{
    window.onscroll = function (e){
        const scrollTop = window.document.body.scrollTop || window.scrollY || window.pageYOffset;
        const elem = document.getElementById("header_panel");
        const upClass = "slide-up", downClass = "slide-down", upThreshold = 50;

        if (elem)
        {
            if (scrollTop >= upThreshold && !commonUtils_HasClass(elem, upClass))
            {
                commonUtils_RemoveClass(elem, downClass);
                commonUtils_AddClass(elem, upClass);
            }

            if (scrollTop < upThreshold && !commonUtils_HasClass(elem, downClass))
            {
                commonUtils_RemoveClass(elem, upClass);
                commonUtils_AddClass(elem, downClass);
            }

            setInterval(function (){
                commonUtils_SetTopMargin();
            }, 100);
        }
    };
}

export function layoutUtils_SetupDynamicModalHeight()
{
    window.onresize = function(e){
        layoutUtils_SetModalHeight();
    };
}

export function layoutUtils_SetModalHeight()
{
    const modal = document.querySelector(".modal-content-opened");

    if (modal)
    {
        modal.style.maxHeight = (window.innerHeight - 160) + "px";
    }
}

export function layoutUtils_ModalOpened()
{
    commonUtils_AddClass(document.getElementById("content"), 'modal-opened');
    layoutUtils_FillPreviewWatermark();
}

export function layoutUtils_ModalClosed()
{
    commonUtils_RemoveClass(document.getElementById("content"), 'modal-opened');
}

export function layoutUtils_LoadingStart()
{
    commonUtils_AddClass(document.body, 'loading');
}

export function layoutUtils_LoadingEnd()
{
    commonUtils_RemoveClass(document.body, 'loading');
}

export function layoutUtils_FillPreviewWatermark()
{
    let html = "", i, inte, wm, attempts = 0;

    inte = setInterval(function(){
        wm = document.querySelector(".preview-watermark");
        attempts++;

        if (wm)
        {
            clearInterval(inte);

            for (i = 0; i < 1000; i++)
            {
                html += " &nbsp;&nbsp;&nbsp;&nbsp;preview";
            }

            wm.innerHTML = html;
        }

        if (attempts > 100)
        {
            clearInterval(inte);
        }
    }, 100);


}

export function layoutUtils_AdjustResourceImages()
{
    const images = document.querySelectorAll(".resource-image");
    let i;

    for (i in images)
    {
        if (images.hasOwnProperty(i))
        {
            if (images[i].clientHeight > 0)
            {
                images[i].style.marginTop = "-" + Math.ceil(images[i].clientHeight / 2) + "px";
            }
        }
    }
}