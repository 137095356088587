import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {generateQuestionName} from "../PreviewUtils";
import {FormControlLabel, Radio, Slider} from "@material-ui/core";

const useStyles = makeStyles({
	slider: {
		width: "100%",
		boxSizing: "border-box",
		padding: "0 96px 24px 96px"
	}
});

export default function SliderListPreview(props) {
	const classes = useStyles(props);
	const questionName = generateQuestionName(props);
	const sliders = [];
	const step = props.question.settings.value.Step;
	const max = props.question.settings.value.MaxValue;
	const min = props.question.settings.value.MinValue;
	const slidersSettings = props.question.settings.value.Sliders;

	for (let i in slidersSettings)
	{
		const marks = [{value: -3, label: slidersSettings[i].Start}, {value: 3, label: slidersSettings[i].End}];
		const value = Math.floor(7 * Math.random()) - 3;

		sliders.push(
			<div className={classes.slider} key={"slider" + i}>
				<Slider defaultValue={value} marks={marks} min={min} max={max} step={step} disabled={true} />
			</div>
		);
	}

	return (
		<React.Fragment>
			<h5>Question preview</h5>
			<h6>{questionName}</h6>
			{sliders}
		</React.Fragment>
	);
}