import {
	commonUtils_CheckAuthorized,
	commonUtils_FormatPercent,
	commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint,
	commonUtils_GetClient,
	commonUtils_GetLocalization,
	commonUtils_GetUser,
	commonUtils_MeasurementId,
	commonUtils_RemoveByClass,
	commonUtils_ResearchId,
	commonUtils_Webalize
} from "./common.utils";
import {filterUtils_GetFromForm, filterUtils_SetResultFilter} from "./filter.utils";
import {
	chartUtils_ApplyStyles,
	chartUtils_GetDummyDataCheckboxList,
	chartUtils_GetDummyDataImageCheckboxList,
	chartUtils_GetDummyDataRadioList,
	chartUtils_GetDummyDataRadioListNegativeBar,
	chartUtils_GetDummyDataSliderList, chartUtils_GetDummyDataTagCloud, chartUtils_SetupChartHover
} from "./chart.utils";
import {
	tableUtils_ApplyStyles,
	tableUtils_GetColIndexByHeaderValue, tableUtils_GetData, tableUtils_GetDataRowsNum,
	tableUtils_GetRowIndexByValue
} from "./table.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "./layout.utils";

/**
 * @param research
 * @return []
 */
export function researchUtils_GetResources(research)
{
	return typeof research.products === "undefined" ? research.brands.value : research.products.value;
}

/**
 * @param research
 * @return []
 */
export function researchUtils_GetOrderedQuestions(research)
{
	let i;
	const allQuestions = research.questionPatterns.value;
	const orderedQids = research.selectedQuestionPatternIds.value;
	const questions = [];

	for (i in allQuestions)
	{
		if (allQuestions.hasOwnProperty(i) && orderedQids.indexOf(allQuestions[i].value.id.value) > -1)
		{
			questions.push(allQuestions[i].value);
		}
	}

	return questions;
}

/**
 * @param callback
 */
export function researchUtils_ReloadResults(callback)
{
	const user = commonUtils_GetUser();
	const localization = commonUtils_GetLocalization();
	const filter = filterUtils_GetFromForm();

	filterUtils_SetResultFilter(filter);
	layoutUtils_LoadingStart();

	fetch(
		commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_GET_RESULTS),
		commonUtils_GetApiCallOptions({
			uid: user.id, token: user.token, co: localization.country,
			cid: commonUtils_GetClient().id, mid: commonUtils_MeasurementId(), rid: commonUtils_ResearchId(),
			f: filter
		})
	)
	.then(blob => blob.json())
	.then(response => {
		if (commonUtils_CheckAuthorized(response))
		{
			response.statusCode === 200 ? callback(response.data) : console.log(response);
		}

		layoutUtils_LoadingEnd();
	})
	.catch(e => {
		console.log(e);
		layoutUtils_LoadingEnd();
		return e;
	});
}

export function researchUtils_RenderResults(responseData)
{
	let qid, i;

	const summaryElems = document.querySelectorAll(".block-result-resource-summary");

	for (i in summaryElems)
	{
		if (summaryElems.hasOwnProperty(i))
		{
			summaryElems[i].innerHTML = "";
		}
	}

	const summaryCallbacks = {
		TagCloud: researchUtils_RenderTagCloudSummary,
		ImageCheckboxList: researchUtils_RenderImageCheckboxListSummary,
		RadioList: researchUtils_RenderRadioListSummary,
		RadioListNegativeBar: researchUtils_RenderRadioListNegativeBarSummary,
		CheckboxList: researchUtils_RenderCheckboxListSummary,
		SliderList: researchUtils_RenderSliderListSummary
	};

	const detailCallbacks = {
		TagCloud: researchUtils_RenderTagCloudDetail,
		ImageCheckboxList: researchUtils_RenderImageCheckboxListDetail,
		RadioList: researchUtils_RenderRadioListDetail,
		RadioListNegativeBar: researchUtils_RenderRadioListNegativeBarDetail,
		CheckboxList: researchUtils_RenderCheckboxListDetail,
		SliderList: researchUtils_RenderSliderListDetail
	}

	for (qid in responseData)
	{
		if (responseData.hasOwnProperty(qid))
		{
			summaryCallbacks[responseData[qid]["cb"]](qid, responseData[qid]);
			detailCallbacks[responseData[qid]["cb"]](qid, responseData[qid]);
		}
	}
}

export function researchUtils_RenderResultsExample(question, resources, chartType)
{
	const qid = question.id.value;

	const callbacks = {
		TagCloud: researchUtils_RenderTagCloudDetail,
		ImageCheckboxList: researchUtils_RenderImageCheckboxListDetail,
		RadioList: researchUtils_RenderRadioListDetail,
		RadioListNegativeBar: researchUtils_RenderRadioListNegativeBarDetail,
		CheckboxList: researchUtils_RenderCheckboxListDetail,
		SliderList: researchUtils_RenderSliderListDetail
	}

	const dummy = {
		TagCloud: chartUtils_GetDummyDataTagCloud,
		ImageCheckboxList: chartUtils_GetDummyDataImageCheckboxList,
		RadioList: chartUtils_GetDummyDataRadioList,
		RadioListNegativeBar: chartUtils_GetDummyDataRadioListNegativeBar,
		CheckboxList: chartUtils_GetDummyDataCheckboxList,
		SliderList: chartUtils_GetDummyDataSliderList
	}

	callbacks[chartType](qid, dummy[chartType](question, resources));
}

function researchUtils_RenderTagCloudSummary(qid, data)
{
	let maxs = data['max'], sums = data['sum'];
	let resourceId, html, perc;

	for (resourceId in sums)
	{
		if (sums.hasOwnProperty(resourceId))
		{
			perc = (100 * maxs[resourceId].value / sums[resourceId]).toFixed(1);
			html = '<p>' + maxs[resourceId].phrase + '</p><small>' + commonUtils_FormatPercent(perc, "of answers") + '</small>';

			document.getElementById("QRS_" + qid + "_" + resourceId).innerHTML = html;
		}
	}
}

function researchUtils_RenderImageCheckboxListSummary(qid, data)
{
	let resourceId, html, resolution, perc;

	for (resourceId in data['rows'])
	{
		if (data['rows'].hasOwnProperty(resourceId))
		{
			if (data['rows'][resourceId][1] >= 56)
			{
				resolution = "ano";
				perc = data['rows'][resourceId][1].toFixed(1);
			}
			else if (data['rows'][resourceId][1] <= 44)
			{
				resolution = "ne";
				perc = (-data['rows'][resourceId][2]).toFixed(1);
			}
			else
			{
				resolution = "50-50";
				perc = (data['rows'][resourceId][1]).toFixed(1);
			}

			html = '<p>' + resolution + '</p><small>' + commonUtils_FormatPercent(perc, "of answers") + '</small>';

			document.getElementById("QRS_" + qid + "_" + resourceId).innerHTML = html;
		}
	}
}

function researchUtils_RenderRadioListSummary(qid, data)
{
	let maxs = data['max'], sums = data['sum'];
	let resourceId, html, perc;

	for (resourceId in sums)
	{
		if (sums.hasOwnProperty(resourceId))
		{
			perc = (100 * maxs[resourceId].value / sums[resourceId]).toFixed(1);
			html = '<p>' + maxs[resourceId].option + '</p><small>' + commonUtils_FormatPercent(perc, "of answers") + '</small>';

			document.getElementById("QRS_" + qid + "_" + resourceId).innerHTML = html;
		}
	}
}

function researchUtils_RenderRadioListNegativeBarSummary(qid, data)
{
	researchUtils_RenderRadioListSummary(qid, data);
}

function researchUtils_RenderCheckboxListSummary(qid, data)
{
	let summary = data['summary'];
	let resourceId, html, i;

	for (resourceId in summary)
	{
		if (summary.hasOwnProperty(resourceId))
		{
			html = "";

			for (i in summary[resourceId])
			{
				if (i > 0) break;
				html += '<p>' + summary[resourceId][i].option
					+ '</p><small>'
					+ commonUtils_FormatPercent(summary[resourceId][i].value.toFixed(1), "of answers")
					+ '</small>';
			}

			document.getElementById("QRS_" + qid + "_" + resourceId).innerHTML = html;
		}
	}
}

function researchUtils_RenderSliderListSummary(qid, data)
{
	let summary = data['summary'];
	let resourceId, html, i, label;

	for (resourceId in summary)
	{
		if (summary.hasOwnProperty(resourceId))
		{
			html = "";

			for (i in summary[resourceId])
			{
				label = data['sliders'][summary[resourceId][i].slider][summary[resourceId][i].side === "+" ? "End" : "Start"];

				html += '<p>' + label
					+ '</p><small>'
					+ commonUtils_FormatPercent(summary[resourceId][i].value.toFixed(1), "of answers")
					+ '</small>';
			}

			document.getElementById("QRS_" + qid + "_" + resourceId).innerHTML = html;
		}
	}
}

export function researchUtils_RenderTagCloudDetail(qid, data)
{
	const elId = "QRD_" + qid, el = document.getElementById(elId);

	el.innerHTML = "";

	let i;
	let stage = window.acgraph.create(elId);
	let charts = [];
	let colors = ['#40c4ff', '#0288d1', '#01579b', '#9fa8da'];
	let chartHeight = 300, y = 0, tableHeight = 0, totalHeight = 0;

	for (i in data['rows'])
	{
		y += tableHeight + 16;

		charts.push(window.anychart.tagCloud());
		charts[i]
		.data(data['rows'][i])
		.colorScale(window.anychart.scales.ordinalColor().colors([colors[i]]))
		.angles([0])
		.textSpacing(5)
		.bounds(0, totalHeight, '100%', 300)
		.legend(true);

		totalHeight += 300;

		chartUtils_ApplyStyles(charts[i], qid);

		charts[i].padding([32, 24, 24, 24]);
		charts[i].container(stage).draw();

		charts[i]
			.tooltip()
			.format(function (){
				return commonUtils_FormatPercent(this.getStat('yPercentOfTotal').toFixed(1));
			});

		let rows = data['rows'][i].split("\n");
		let sum = 0, phrases = {};

		for (let r in rows)
		{
			let row = rows[r].split(",");

			phrases[row[0]] = phrases[row[0]] || 0;
			phrases[row[0]] += parseInt(row[1]);

			sum += parseInt(row[1]);
		}

		let sortablePhrases = [];
		for (let p in phrases)
		{
			sortablePhrases.push([p, commonUtils_FormatPercent((100 * phrases[p] / sum).toFixed(1))]);
		}
		sortablePhrases.sort(function(a, b) {
			if (commonUtils_Webalize(a[0]).toLowerCase() === 'jine')
			{
				return 1;
			}

			if (commonUtils_Webalize(b[0]).toLowerCase() === 'jine')
			{
				return -1;
			}

			return parseFloat(b[1]) - parseFloat(a[1]);
		});

		let table = window.anychart.standalones.table();

		tableUtils_ApplyStyles(table, qid);

		y += chartHeight + 16;
		tableHeight = 32 * sortablePhrases.length;

		table.contents(sortablePhrases);
		table.getCol(0).width(200).fontWeight(900).hAlign("left");
		table.bounds(0, totalHeight, "100%", 32 * sortablePhrases.length);
		totalHeight += 32 * sortablePhrases.length;
		table.container(stage).draw();

		charts[i].listen("mouseOver", function(ev) {
			if (ev.target.de === undefined) return;
			const seriesName = ev.target.de.x;
			const rowIndex = parseInt(tableUtils_GetRowIndexByValue(table, seriesName, 0));
			const rowsNum = tableUtils_GetDataRowsNum(table);
			let i;

			for (i = 0; i < rowsNum; i++)
			{
				if (i !== rowIndex)
				{
					table.getRow(i).cellFill("#fff", 1);
				}
				else
				{
					table.getRow(i).cellFill("#f2f2f2", 1);
				}
			}
		});
	}

	el.style.height = totalHeight + "px";

	commonUtils_RemoveByClass(document.querySelectorAll(".anychart-credits"));

	researchUtils_AppendNumRespondentsInfo(data, el);
}

function researchUtils_RenderImageCheckboxListDetail(qid, data)
{
	const elId = "QRD_" + qid, el = document.getElementById(elId);

	el.innerHTML = "";

	let stage = window.anychart.graphics.create(elId);
	let chartData = [], tableData = [["#", "Yes", "No"]], totalHeight = 0;

	for (let i in data['rows'])
	{
		if (data['rows'].hasOwnProperty(i))
		{
			tableData.push([
				data['rows'][i][0],
				data['rows'][i][1].toFixed(1) + " %",
				Math.abs(data['rows'][i][2]).toFixed(1) + " %"
			]);

			chartData.push(data['rows'][i]);
		}
	}

	let dataSet = window.anychart.data.set(chartData);
	let firstSeriesData = dataSet.mapAs({ x: 0, value: 1 }), secondSeriesData = dataSet.mapAs({ x: 0, value: 2 });

	let chart = window.anychart.bar();

	chartUtils_ApplyStyles(chart, qid);

	chart.yScale().minimum(-100);
	chart.yScale().maximum(100);
	chart.yScale().ticks().interval(25);
	chart.yScale().stackMode('value');

	chart.yAxis().labels().format(function (){
		return Math.abs(this.value);
	});
	chart.yAxis(0).title('Percent of respondents');

	chart.xAxis(1).enabled(true).orientation('right');

	chart.interactivity().hoverMode('by-x');

	chart
	.tooltip()
	.title(false)
	.separator(false)
	.displayMode('separated')
	.positionMode('point')
	.useHtml(true)
	.fontSize(12)
	.offsetX(5)
	.offsetY(0)
	.format(function (){
		return Math.abs(this.value).toFixed(1) + " %";
	});

	let series;

	series = chart.bar(firstSeriesData);
	series.name('Yes');
	series.tooltip().position('right').anchor('left-center');

	series = chart.bar(secondSeriesData);
	series.name('No');
	series.tooltip().position('left').anchor('right-center');

	chart
		.legend()
		.enabled(true)
		.inverted(true)
		.fontSize(13);

	chart.bounds(0, 0, "100%", 300);
	totalHeight += 300;
	chart.container(stage).draw();

	let table = window.anychart.standalones.table();

	tableUtils_ApplyStyles(table, qid);

	table.contents(tableData);
	table.getRow(0).fontWeight(900);
	table.getCol(0).width(150).fontWeight(900).hAlign("left");
	table.bounds(0, 300, "100%", 32 * tableData.length);
	totalHeight += 32 * tableData.length;
	table.container(stage).draw();

	chartUtils_SetupChartHover(chart, table);

	el.style.height = totalHeight + "px";
	commonUtils_RemoveByClass(document.querySelectorAll(".anychart-credits"));

	researchUtils_AppendNumRespondentsInfo(data, el);
}

function researchUtils_RenderRadioListDetail(qid, data)
{
	const elId = "QRD_" + qid, el = document.getElementById(elId);

	el.innerHTML = "";

	let stage = window.acgraph.create(elId);
	let chart, chartData, resource, i, j, sum, tableData = [['#']], totalHeight = 0;

	chartData = {
		header: ['#'],
		rows: []
	};

	i = 0;
	for (resource in data['rows'])
	{
		if (data['rows'].hasOwnProperty(resource))
		{
			sum = data['rows'][resource].reduce(function(a, b){
				return a + b[1];
			}, 0);

			for (j in data['rows'][resource])
			{
				chartData.rows[j] = chartData.rows[j] || [data['rows'][resource][j][0]];
				chartData.rows[j].push(100 * data['rows'][resource][j][1] / sum);
			}

			chartData.header.push(resource);
			tableData[0].push(resource);
			i++;
		}
	}

	tableData = tableData.concat(chartData.rows);

	chart = window.anychart.column();

	// set chart data
	chart.data(chartData);

	// turn on chart animation
	chart.animation(true);

	chart.yAxis().labels().format(function(){
		return commonUtils_FormatPercent(this.value.toFixed(1));
	});
	chart.yAxis().title('% of answers');

	// turn on legend and tune it
	chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]);

	chart
		.tooltip()
		.position('right')
		.anchor('left-center')
		.offsetX(5)
		.offsetY(0)
		.titleFormat('{%X}')
		.format(function(){
			return this.seriesName + ": " + commonUtils_FormatPercent(this.value.toFixed(1));
		});

	chart.xAxis().labels().fontSize(10).width(100).hAlign("center");

	chart.xGrid().enabled(true).stroke({
		color: "#dfdfdf",
		dash: "1 2"
	});;

	chart.bounds(0, 0, '100%', 380);
	totalHeight += 380;
	chart.container(stage);
	chartUtils_ApplyStyles(chart, qid);

	chart.draw();

	let table = window.anychart.standalones.table();

	tableUtils_ApplyStyles(table, qid);

	tableData.sort(function (a, b){
		if (a[0] === '#') return -1;
		if (b[0] === '#') return 1;
		return a[1] > b[1] ? -1 : 1;
	});

	for (i in tableData)
	{
		if (i > 0)
		{
			tableData[i][1] = commonUtils_FormatPercent(tableData[i][1].toFixed(1));
			tableData[i][2] = commonUtils_FormatPercent(tableData[i][2].toFixed(1));
			tableData[i][3] = commonUtils_FormatPercent(tableData[i][3].toFixed(1));
			tableData[i][4] = commonUtils_FormatPercent(tableData[i][4].toFixed(1));
		}
	}

	table.contents(tableData);
	table.getCol(0).width(150).fontWeight(900).hAlign("left");
	table.getRow(0).fontWeight(900);
	table.bounds(0, totalHeight, '100%', 32 * tableData.length);
	totalHeight += 32 * tableData.length;
	table.container(stage).draw();

	el.style.height = totalHeight + "px";

	chartUtils_SetupChartHover(chart, table);

	commonUtils_RemoveByClass(document.querySelectorAll(".anychart-credits"));

	researchUtils_AppendNumRespondentsInfo(data, el);
}

function researchUtils_RenderRadioListNegativeBarDetail(qid, data)
{
	const elId = "QRD_" + qid, el = document.getElementById(elId);

	el.innerHTML = "";

	let stage = window.anychart.graphics.create(elId);
	let chartData = [], tableData = [["#", "Yes", "No"]], percYes, totalHeight = 0;

	for (let resourceName in data['rows'])
	{
		if (data['rows'].hasOwnProperty(resourceName))
		{
			percYes = 100 * data['rows'][resourceName][0][1] / (data['rows'][resourceName][0][1] + data['rows'][resourceName][1][1]);

			tableData.push([
				resourceName,
				percYes.toFixed(1) + " %",
				Math.abs(100 - percYes).toFixed(1) + " %"
			]);

			chartData.push([resourceName, percYes, -(100 - percYes)]);
		}
	}

	let dataSet = window.anychart.data.set(chartData);
	let firstSeriesData = dataSet.mapAs({ x: 0, value: 1 }), secondSeriesData = dataSet.mapAs({ x: 0, value: 2 });

	let chart = window.anychart.bar();

	chartUtils_ApplyStyles(chart, qid);

	chart.padding([0, 20, 5, 20]);

	chart.yScale().minimum(-100);
	chart.yScale().maximum(100);
	chart.yScale().ticks().interval(25);
	chart.yScale().stackMode('value');

	chart.yAxis().labels().format(function (){
		return Math.abs(this.value);
	});
	chart.yAxis(0).title('Percent of respondents');

	chart.xAxis(1).enabled(true).orientation('right');

	chart.interactivity().hoverMode('by-x');

	chart
	.tooltip()
	.title(false)
	.separator(false)
	.displayMode('separated')
	.positionMode('point')
	.useHtml(true)
	.fontSize(12)
	.offsetX(5)
	.offsetY(0)
	.format(function (){
		return Math.abs(this.value).toFixed(1) + " %";
	});

	let series;

	series = chart.bar(firstSeriesData);
	series.name('Yes');
	series.tooltip().position('right').anchor('left-center');

	series = chart.bar(secondSeriesData);
	series.name('No');
	series.tooltip().position('left').anchor('right-center');

	chart
	.legend()
	.enabled(true)
	.inverted(true)
	.fontSize(13);

	chart.bounds(0, 0, "100%", 300);
	totalHeight += 300;
	chart.container(stage).draw();

	let table = window.anychart.standalones.table();

	tableUtils_ApplyStyles(table, qid);

	table.contents(tableData);
	table.getRow(0).fontWeight(900);
	table.getCol(0).width(150).fontWeight(900).hAlign("left");
	table.bounds(0, totalHeight, "100%", 32 * tableData.length);
	totalHeight += 32 * tableData.length;
	table.container(stage).draw();

	el.style.height = totalHeight + "px";

	chartUtils_SetupChartHover(chart, table);

	commonUtils_RemoveByClass(document.querySelectorAll(".anychart-credits"));

	researchUtils_AppendNumRespondentsInfo(data, el);
}

function researchUtils_RenderCheckboxListDetail(qid, data)
{
	const elId = "QRD_" + qid, el = document.getElementById(elId);

	el.innerHTML = "";

	let stage = window.anychart.graphics.create(elId);
	let totalHeight = 0;

	let xLabels = Object.keys(data['rows'][0]);

	let chartData = {
		header: ['#'],
		rows: []
	};

	let resourceIds = Object.keys(data['rows'][0][xLabels[0]]);

	for (let r in resourceIds)
	{
		chartData.header.push(data['rows'][1][resourceIds[r]].name);
	}

	for (let i in xLabels)
	{
		chartData.rows[i] = [xLabels[i]];

		for (let r in resourceIds)
		{
			chartData.rows[i].push(data['rows'][0][xLabels[i]][resourceIds[r]]);
		}
	}

	let chart = window.anychart.column3d();

	chartUtils_ApplyStyles(chart, qid);

	chart.data(chartData);

	let yScale = new window.anychart.scales.linear();
	yScale.ticks().interval(10);
	chart.yScale(yScale);

	chart.yAxis().labels().format(function(){
		return commonUtils_FormatPercent(this.value.toFixed(1));
	});

	chart.yAxis().title('% of answers');
	chart.xAxis().labels().rotation(270).width(120).hAlign("center").padding("10px").fontSize(10);
	chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]);
	chart.interactivity().hoverMode('single');

	let xLabelBg = chart.xAxis().labels().background();
	xLabelBg.enabled(true);
	xLabelBg.fill("#fafafa");

	chart
	.tooltip()
	.positionMode('point')
	.position('center-top')
	.anchor('center-bottom')
	.offsetX(0)
	.offsetY(5)
	.format(function (){
		return this.seriesName + ": " + commonUtils_FormatPercent(this.value.toFixed(1));
	});

	chart.zAspect('10%').zPadding(20).zAngle(45).zDistribution(true);
	chart.bounds(0, 0, "100%", 600);
	totalHeight += 600;
	chart.container(stage).draw();

	let table = window.anychart.standalones.table();

	tableUtils_ApplyStyles(table, qid);

	let tableData = [chartData.header];

	for (let i in chartData.rows)
	{
		let r = [];

		for (let j in chartData.rows[i])
		{
			if (j > 0)
			{
				r.push(chartData.rows[i][j].toFixed(1) + " %");
			}
			else
			{
				r.push(chartData.rows[i][j]);
			}
		}

		tableData.push(r);
	}

	tableData.sort(function (a, b){

		if (a[0] === "#") return -1;

		if (['zadne-z-uvedenych', 'jinde'].indexOf(commonUtils_Webalize(a[0]).toLowerCase()) > -1)
		{
			return 1;
		}

		if (['zadne-z-uvedenych', 'jinde'].indexOf(commonUtils_Webalize(b[0]).toLowerCase()) > -1)
		{
			return -1;
		}

		return parseFloat(a[1]) > parseFloat(b[1]) ? -1 : 1;
	});

	table.contents(tableData);

	table.getRow(0).fontWeight(900);
	table.getCol(0).width(150).fontWeight(900).hAlign("left");
	table.bounds(0, 600, "100%", 32 * tableData.length);
	totalHeight += 32 * tableData.length;
	table.container(stage).draw();

	el.style.height = totalHeight + "px";

	chartUtils_SetupChartHover(chart, table);

	commonUtils_RemoveByClass(document.querySelectorAll(".anychart-credits"));

	researchUtils_AppendNumRespondentsInfo(data, el);
}

function researchUtils_RenderSliderListDetail(qid, data)
{
	const elId = "QRD_" + qid, el = document.getElementById(elId);

	el.innerHTML = "";

	let stage = window.acgraph.create(elId);
	let objectIndex = -1, sliderIndex, j, resourceId, totalSize = 0;

	// helper function to setup label settings for all series
	let setupSeriesLabels = function (series, name) {
		series.name(name).stroke('3 #fff 1');
		series.hovered().stroke('3 #fff 1');
	};

	let sliders = data['sliders'];
	let min = -3;
	let max = 3;

	for (resourceId in data['rows'][0])
	{
		if (data['rows'][0].hasOwnProperty(resourceId))
		{
			objectIndex += 1;

			let chartData = [], dataSet, seriesMapping = [], series = [], idx;
			let chart = window.anychart.bar();

			chartUtils_ApplyStyles(chart, qid);

			idx = -1;
			for (sliderIndex in data['rows'][0][resourceId])
			{
				idx += 1;
				let label = sliders[sliderIndex].Start + "/" + sliders[sliderIndex].End;

				chartData.push([label]);

				for (j = min; j <= max; j++)
				{
					chartData[idx].push(data['rows'][0][resourceId][sliderIndex][j] || 0);
				}
			}

			dataSet = window.anychart.data.set(chartData);

			idx = -1;
			for (j = min; j <= max; j++)
			{
				idx += 1;
				seriesMapping.push(dataSet.mapAs({x: 0, value: idx + 1}));
				series.push(chart.bar(seriesMapping[idx]));
				setupSeriesLabels(series[idx], j);
			}

			chart.yScale().stackMode('percent');
			chart.title(data['rows'][1][resourceId]['name']);
			chart.yAxis(0).labels().format('{%Value}%');
			chart.yAxis().title('% of answers');

			chart.legend()
			.enabled(true)
			.fontSize(14)
			.padding([0, 0, 15, 0]);

			chart.interactivity().hoverMode('by-x');
			chart.tooltip().displayMode('union');
			chart.tooltip().format('{%seriesName}: {%yPercentOfCategory} %');
			chart.tooltip().format(function () {
				return this.seriesName + ": " + commonUtils_FormatPercent(this.getStat('yPercentOfCategory').toFixed(1));
			});

			chart.bounds(0, totalSize, "100%", 360);
			chart.padding([32, 24, 24, 24]);
			totalSize += 360;
			chart.container(stage).draw();

			objectIndex++;

			let table = window.anychart.standalones.table();

			tableUtils_ApplyStyles(table, qid);

			let tableData = [["#", -3, -2, -1, 0, 1, 2, 3]];

			for (let i in chartData)
			{
				let r = [];
				let sum = chartData[i].reduce(function (a, b) {
					return (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b);
				}, 0);

				for (let j in chartData[i])
				{
					if (j > 0)
					{
						r.push((100 * chartData[i][j] / sum).toFixed(1) + " %");
					}
					else
					{
						r.push(chartData[i][j]);
					}
				}

				tableData.push(r);
			}

			table.contents(tableData);
			table.getRow(0).fontWeight(900);
			table.getCol(0).width(150).fontWeight(900).hAlign("left");
			table.bounds(0, totalSize, "100%", 32 * tableData.length);
			totalSize += 32 * tableData.length;
			table.container(stage).draw();

			chartUtils_SetupChartHover(chart, table);
		}

		el.style.height = totalSize + "px";
	}

	commonUtils_RemoveByClass(document.querySelectorAll(".anychart-credits"));

	researchUtils_AppendNumRespondentsInfo(data, el);
}

function researchUtils_AppendNumRespondentsInfo(data, el)
{
	const container = document.createElement('div');

	container.innerText = data['respondents'] + ' respondents asked';
	container.style.width = "100%";
	container.style.textAlign = "right";
	container.style.color = "#000";
	container.style.font = "normal normal normal 11px/15px Open Sans";

	el.appendChild(container);

	const elHeight = parseInt(window.getComputedStyle(el).height);

	el.style.height = (elHeight + 24) + "px";
}