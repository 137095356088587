import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CheckboxListPreview from "./CheckboxListPreview";
import RadioListPreview from "./RadioListPreview";
import TagCloudPreview from "./TagCloudPreview";
import ImageCheckboxListPreview from "./ImageCheckboxListPreview";
import TextPreview from "./TextPreview";
import SliderListPreview from "./SliderListPreview";
import {Modal} from "@material-ui/core";
import {layoutUtils_FillPreviewWatermark} from "../utils/layout.utils";

const useStyles = makeStyles({
	modalWindow: {
		position: 'absolute',
		width: 800,
		borderRadius: 24,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		padding: "24px 32px 40px 32px",
		"& h5": {
			font: "normal normal normal 16px/25px Montserrat",
			color: "#000000",
			padding: "0 0 24px 0",
			borderBottom: "1px solid #DBE5EE",
			margin: 0,
			marginBottom: 24
		},
		"& h6": {
			font: "normal normal normal 16px/25px Montserrat",
			color: "#000000",
			margin: 0,
			marginBottom: 24
		},
		"& .close": {
			position: "absolute",
			right: 32,
			top: 24,
			zIndex: 999
		}
	}
});

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
		overflow: "hidden"
	};
}

export default function QuestionPreviewModal(props) {
	const classes = useStyles(props);
	const [modalStyle] = useState(getModalStyle);

	function getRandomKey(obj)
	{
		let keys = Object.keys(obj);

		if (keys.length === 0) return null;

		return keys[ keys.length * Math.random() << 0];
	}

	const isBrand = props.research.brands !== undefined;
	const isProduct = props.research.products !== undefined;
	const randomBrandIndex = isBrand ? getRandomKey(props.research.brands.value) : null;
	const randomProductIndex = isProduct ? getRandomKey(props.research.products.value) : null;

	let previewComponents = {
		"CheckboxList": <CheckboxListPreview question={props.question} research={props.research} brandIndex={randomBrandIndex} productIndex={randomProductIndex}/>,
		"RadioList": <RadioListPreview question={props.question} research={props.research} brandIndex={randomBrandIndex} productIndex={randomProductIndex}/>,
		"TagCloud": <TagCloudPreview question={props.question} research={props.research} brandIndex={randomBrandIndex} productIndex={randomProductIndex}/>,
		"ImageCheckboxList": <ImageCheckboxListPreview question={props.question} research={props.research} brandIndex={randomBrandIndex} productIndex={randomProductIndex}/>,
		"Text": <TextPreview question={props.question} research={props.research} brandIndex={randomBrandIndex} productIndex={randomProductIndex}/>,
		"SliderList": <SliderListPreview question={props.question} research={props.research} brandIndex={randomBrandIndex} productIndex={randomProductIndex}/>
	}

	return (
		<Modal open={props.open} onClose={props.handleClose} disableEnforceFocus disableAutoFocus>
			<div style={modalStyle} className={classes.modalWindow}>
				<a href="#" onClick={e => props.handleClose(e)} className="close"><img src="/icon/cross.png" alt="Close"/></a>
				{previewComponents[props.question.htmlType.value]}
				<div className="preview-watermark"> </div>
			</div>
		</Modal>
	);
}