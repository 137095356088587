import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "./layout.utils";

export function commonUtils_GetAppMode()
{
	let hostParts = window.location.hostname.split(".");
	return hostParts.length > 3 ? hostParts[0] : "";
}

export function commonUtils_GetApiBaseUrl()
{
	const appMode = commonUtils_GetAppMode();
	const baseHost = "api.research.adarrows.com";

	return new URL(window.location.protocol + "//" + (appMode ? appMode + "." : "") + baseHost);
}

export function commonUtils_SetData(data)
{
	localStorage.setItem('data', JSON.stringify(data));
}

export function commonUtils_GetData()
{
	return localStorage.getItem('data') ? JSON.parse(localStorage.getItem('data')) : {};
}

export function commonUtils_GetApiEndpoint(path)
{
	return commonUtils_GetApiBaseUrl() + path;
}

export function commonUtils_GetApiCallOptions(args, headers)
{
	return {
		method: 'POST',
		headers: headers || {Accept: 'application/json'},
		credentials: 'include',
		body: JSON.stringify(args)
	};
}

export function commonUtils_CheckAuthorized(apiResponse)
{
	if (apiResponse.statusCode === 401)
	{
		localStorage.removeItem('u');
		commonUtils_RedirectTo('/sign-out');
		return false;
	}

	return true;
}

export function commonUtils_GetLocationHashArgs()
{
	let str = document.location.hash;
	let args = {};

	if (typeof str === 'string' && str.length > 7)
	{
		args = JSON.parse(decodeURIComponent(str.substr(1)));
	}

	return args;
}

export function commonUtils_SetLocationHashArg(name, value)
{
	let obj = commonUtils_GetLocationHashArgs();

	obj[name] = value;

	document.location.hash = encodeURIComponent(JSON.stringify(obj));
}

export function commonUtils_GetUser()
{
	return localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : {};
}

export function commonUtils_IsAuthenticated()
{
	return !(typeof commonUtils_GetUser().id === "undefined");
}

export function commonUtils_GetLocalization()
{
	return {lang: "cs", country: "cz"};
}

export function commonUtils_LoadData(callback)
{
	if (commonUtils_IsAuthenticated())
	{
		layoutUtils_LoadingStart();

		const user = commonUtils_GetUser();
		const localization = commonUtils_GetLocalization();
		const urlState = commonUtils_GetUrlState();

		commonUtils_SetData({});

		fetch(
			commonUtils_GetApiEndpoint(process.env.REACT_APP_API_DATA_GET),
			commonUtils_GetApiCallOptions({
				uid: user.id,
				token: user.token,
				co: localization.country,
				mid: urlState.measurement || null,
				rid: urlState["new-research"] || null
			})
		)
		.then(blob => blob.json())
		.then(response => {
			layoutUtils_LoadingEnd();

			if (commonUtils_CheckAuthorized(response)) {
				if (response.statusCode === 200) {
					commonUtils_SetData(response.data);
				}

				callback(response);
			}
		})
		.catch(e => {
			console.log(e);
			layoutUtils_LoadingEnd();
			return e;
		});
	}
	else if (!commonUtils_IsPublic())
	{
		commonUtils_RedirectTo('/sign-in');
	}
	else
	{
		callback({statusCode: 401});
	}
}

export function commonUtils_Ping(callback)
{
	layoutUtils_LoadingStart();

	fetch(
		commonUtils_GetApiEndpoint(process.env.REACT_APP_API_PING),
		commonUtils_GetApiCallOptions({})
	)
	.then(blob => blob.text())
	.then(r => {
		layoutUtils_LoadingEnd();
		callback();
	})
	.catch(e => {
		console.log(e);
		layoutUtils_LoadingEnd();
		return e;
	});
}

export function commonUtils_RedirectTo(path)
{
	document.location = path;
}

export function commonUtils_IsPublic()
{
	let path = document.location.pathname;
	let publicSites = ['/sign-in', '/sign-up', '/sign-up/success', '/password-recovery', '/password-recovery-request', '/account-activation', '/mailing-unsubscribe', '/example'];

	return publicSites.indexOf(path) > -1;
}

export function commonUtils_GetUrlState()
{
	let parts = window.location.pathname.substring(1).split('/');
	let state = {};

	for (let i = 0; i < parts.length; i+=2)
	{
		state[parts[i]] = parts[i+1];
	}

	return state;
}

export function commonUtils_SetTopMargin()
{
	window.setTimeout(function (){
		let header = document.getElementById("header");

		if (header)
		{
			const headerHeight = header.offsetHeight;
			document.getElementById("content").style.marginTop = (headerHeight) + "px";
		}
	}, 200);
}

export function commonUtils_GetClient()
{
	return commonUtils_GetData().client || null;
}

export function commonUtils_GetMeasurement()
{
	let client = commonUtils_GetClient();
	let mid = commonUtils_MeasurementId();

	if (client && typeof client.measurements !== "undefined")
	{
		return client.measurements[mid] ? client.measurements[mid].value : null;
	}

	return null;
}

export function commonUtils_GetResearch()
{
	let measurement = commonUtils_GetMeasurement();
	let rid = commonUtils_ResearchId();

	if (measurement && typeof measurement.researches !== "undefined")
	{
		return measurement.researches[rid] ? measurement.researches[rid].value : null;
	}

	return null;
}

export function commonUtils_BaseUrl()
{
	let port = parseInt(window.location.port);

	return window.location.protocol + "//" + window.location.hostname + (!port || port === 80 || port === 443 ? '' : ':' + port);
}

export function commonUtils_MeasurementUrl(mid)
{
	return commonUtils_BaseUrl() + "/measurement/" + mid;
}

export function commonUtils_NewResearchUrl(mid, rid)
{
	return commonUtils_BaseUrl() + "/measurement/" + mid + "/new-research/" + rid;
}

export function commonUtils_SummaryUrl(mid, rid)
{
	return commonUtils_BaseUrl() + "/measurement/" + mid + "/new-research/" + rid + "/summary/1";
}

export function commonUtils_PaymentUrl(mid, rid)
{
	return commonUtils_BaseUrl() + "/measurement/" + mid + "/new-research/" + rid + "/payment/1";
}

export function commonUtils_ResearchUrl(mid, rid)
{
	return commonUtils_BaseUrl() + "/measurement/" + mid + "/research/" + rid;
}

export function commonUtils_MeasurementId()
{
	return parseInt(commonUtils_GetUrlState()["measurement"]);
}

export function commonUtils_ResearchId()
{
	let urlState = commonUtils_GetUrlState();
	return parseInt(urlState["research"] || urlState["new-research"]);
}

export function commonUtils_RemoveClass(el, cl)
{
	let reg = new RegExp('\\b' + cl + '\\b', 'g');
	el.className = el.className.replace(reg, "");
}

export function commonUtils_AddClass(el, cl)
{
	el.className += " " + cl;
}

export function commonUtils_HasClass(el, cl)
{
	let reg = new RegExp('\\b' + cl + '\\b', 'g');
	return reg.test(el.className);
}

export function commonUtils_DownloadCurrentAsPdf(e)
{
	e.preventDefault();

	const filename = e.target.dataset.filename;
	const user = commonUtils_GetUser();
	const localization = commonUtils_GetLocalization();

	let helperElem = document.createElement('div');
	helperElem.style.display = "none";
	document.body.appendChild(helperElem);

	let content = "<html><head><style>*{font-family:sans-serif;}</style></head><body>";

	content += '<img src="https://client.h24.cz/h24-logo.png" style="width:160px;">';
	content += '<p><a href="' + document.location.href + '">' + document.location.href + '</a></p>';

	helperElem.innerHTML = document.getElementById("header_panel").innerHTML;
	commonUtils_RemoveNotPrintableElements(helperElem);
	content += helperElem.innerHTML;

	helperElem.innerHTML = document.getElementById("content").innerHTML;
	commonUtils_RemoveNotPrintableElements(helperElem);
	commonUtils_ReplaceQrCode(helperElem);
	content += helperElem.innerHTML;

	content += "</body></html>";

	helperElem.parentNode.removeChild(helperElem);

	layoutUtils_LoadingStart();

	fetch(
		commonUtils_GetApiEndpoint(process.env.REACT_APP_API_EXPORT_PAGE),
		commonUtils_GetApiCallOptions({
			uid: user.id,
			token: user.token,
			co: localization.country,
			html: content
		}, {})
	)
	.then(res => res.blob())
	.then(blob => {
		let url = window.URL.createObjectURL(blob);
		let a = document.createElement('a');
		a.href = url;
		a.download = commonUtils_Webalize(filename) + '.pdf';
		document.body.appendChild(a);
		a.click();
		a.remove();

		layoutUtils_LoadingEnd();
	})
	.catch(e => {
		console.log(e);
		layoutUtils_LoadingEnd();
		return e;
	});
}

export function commonUtils_Webalize(str)
{
	let i, re;
	let chars = [
		['Á','A'], ['Ä','A'], ['Č','C'], ['Ç','C'], ['Ď','D'], ['É','E'], ['Ě','E'],
		['Ë','E'], ['Í','I'], ['Ň','N'], ['Ó','O'], ['Ö','O'], ['Ř','R'], ['Š','S'],
		['Ť','T'], ['Ú','U'], ['Ů','U'], ['Ü','U'], ['Ý','Y'], ['Ž','Z'], ['á','a'],
		['ä','a'], ['č','c'], ['ç','c'], ['ď','d'], ['é','e'], ['ě','e'], ['ë','e'],
		['í','i'], ['ň','n'], ['ó','o'], ['ö','o'], ['ř','r'], ['š','s'], ['ť','t'],
		['ú','u'], ['ů','u'], ['ü','u'], ['ý','y'], ['ž','z']
	];

	for (i in chars)
	{
		re = new RegExp(chars[i][0],'g');
		str = str.replace(re, chars[i][1]);
	}

	str = str.replace(/[^a-z0-9]/ig, '-');
	str = str.replace(/\-+/g, '-');

	if (str[0] === '-')
	{
		str = str.substring(1, str.length);
	}

	if (str[str.length - 1] === '-')
	{
		str = str.substring(0, str.length - 1);
	}

	return str.toLowerCase();
}

function commonUtils_RemoveNotPrintableElements(el)
{
	let i;
	const els = el.querySelectorAll(".not-printable");

	for (i in els)
	{
		if (els.hasOwnProperty(i))
		{
			els[i].parentNode.removeChild(els[i]);
		}
	}
}

function commonUtils_ReplaceQrCode(el)
{
	let i;
	const els = el.querySelectorAll("canvas");

	for (i in els)
	{
		if (els.hasOwnProperty(i))
		{
			let div = document.createElement('div');
			div.className = "qrcode";
			div.dataset.qrcode = els[i].dataset.source;

			els[i].parentNode.insertBefore(div, els[i]);
			els[i].parentNode.removeChild(els[i]);
		}
	}
}

export function commonUtils_NumberFormat(num)
{
	let l = commonUtils_GetLocalization();

	return (new Intl.NumberFormat(l.lang + '-' + l.country.toUpperCase())).format(num);
}

export function commonUtils_ClearSelection()
{
	if (window.getSelection) {window.getSelection().removeAllRanges();}
	else if (document.selection) {document.selection.empty();}
}

export function commonUtils_RemoveByClass(elms)
{
	elms.forEach(el => el.remove());
}

export function commonUtils_FormatPercent(value, suffix)
{
	return value + " %" + (suffix ? " " + suffix : "");
}

export function commonUtils_FormatPercentage(value, suffix)
{
	return value + "%" + (suffix ? " " + suffix : "");
}

export function commonUtils_FireWixTracker(name, email, phone, labels, callback)
{
	if (!email)
	{
		return;
	}

	const ifr = document.createElement('iframe');
	const data = {};
	const nameSplitted = name.split(" ");

	if (name.length > 0)
	{
		if (nameSplitted.length > 2)
		{
			data.firstName = name;
			data.lastName = "";
		}
		else
		{
			data.firstName = nameSplitted[0];
			data.lastName = nameSplitted[1];
		}
	}

	data.emails = [email];

	if (phone)
	{
		data.phones = [phone];
	}

	if (labels)
	{
		data.labels = labels;
	}

	ifr.src = "https://www.h24.cz/client-h24-cz?args=" + encodeURIComponent(JSON.stringify(data));
	ifr.onload = callback();

	ifr.style.width = "1px";
	ifr.style.height = "1px";
	ifr.style.position = "absolute";
	ifr.style.left = "-1000px";
	ifr.style.left = "0";

	document.body.appendChild(ifr);
}
