import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Logo from "../Logo";

const useStyles = makeStyles({
	h1: {
		borderBottom: "1px solid #DBE5EE",
		marginTop: 40,
		marginBottom: 29,
		paddingBottom: 24
	}
});

export default function AuthHeader(props) {
	const classes = useStyles();

	return (
		<Box className="sign-form-header">
			<Logo width="118px" margin="0 auto"/>
			<h1 className={classes.h1}>{props.content}</h1>
		</Box>
	);
}