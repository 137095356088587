import React, {useEffect} from "react";
import BlockLabels from "../../BlockLabels";
import BlockBrandSettings from "../BlockBrandSettings";
import BlockProductSettings from "../BlockProductSettings";
import Result_Question from "./Result_Question";
import BlockResultFilter from "../BlockResultFilter";
import {
	researchUtils_GetOrderedQuestions
} from "../../utils/research.utils";

export default function Result_Research(props) {
	const questions = researchUtils_GetOrderedQuestions(props.research);
	const questionBlocks = [];
	let resourcesBlock;

	for (let i in questions)
	{
		questionBlocks.push(
			<Result_Question
				question={questions[i]}
				research={props.research}
				measurement={props.measurement}
				client={props.client}
				key={i}
				opened={false}
			/>);
	}

	if (props.measurement.researches[props.research.id.value].type === "App.Model.Research.ProductResearch")
	{
		resourcesBlock = (
			<React.Fragment>
				<BlockLabels text1="Products" margin="32px 0 16px 32px"/>
				<BlockProductSettings client={props.client}
									  measurement={props.measurement}
									  research={props.research}
									  isEditable={false}
									  handleFileSelected={props.handleFileSelected}/>
			</React.Fragment>);
	}
	else
	{
		resourcesBlock = (
			<React.Fragment>
				<BlockLabels text1="Brands" margin="32px 0 16px 32px"/>
				<BlockBrandSettings client={props.client}
									measurement={props.measurement}
									research={props.research}
									isEditable={false}
									handleFileSelected={props.handleFileSelected}/>
			</React.Fragment>);
	}

	useEffect(() => {
		document.querySelector(".btn-apply").click();
	});

	return (
		<React.Fragment>
			{resourcesBlock}
			<div className="clearfix"> </div>

			<BlockLabels text1="Filter" mr1="0" margin="32px 0 16px 32px"/>
			<BlockResultFilter/>
			<div className="clearfix"> </div>

			<BlockLabels text1="Questions result" mr1="0" margin="32px 0 0 32px"/>
			{questionBlocks}
			<div className="clearfix mb64"> </div>
		</React.Fragment>
	);
}