import {Box, Button} from "@material-ui/core";
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Alert} from "@material-ui/lab";
import {commonUtils_GetApiCallOptions, commonUtils_GetApiEndpoint, commonUtils_RedirectTo} from "../utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";

const useStyles = makeStyles({
	textField: {
		marginBottom: 30,
		borderRadius: 8
	},
	btnBox: {
		marginTop: 32,
		marginBottom: 32
	},
	alert: {
		marginBottom: 32,
		borderRadius: 24
	}
});

export default function PasswordRecoveryForm() {
	const classes = useStyles();

	const url = new URL(window.location.href);

	const [pwd, setPwd] = useState("");
	const [pwd2, setPwd2] = useState("");
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("info");
	const [hash] = useState(url.searchParams.get('h'));

	function handleSubmit(event)
	{
		event.preventDefault();

		if (pwd && pwd2 && pwd === pwd2)
		{
			layoutUtils_LoadingStart();

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_PASSWORD_RECOVERY),
				commonUtils_GetApiCallOptions({p1: pwd, p2: pwd2, h: hash})
			)
			.then(blob => blob.json())
			.then(response => {
				if (response.statusCode === 200)
				{
					commonUtils_RedirectTo('/sign-in?recovery_success');
				}
				else if (response.statusCode < 500)
				{
					setAlertType("warning");
					setAlert(response.data.detail);
					console.log(response.statusMessage);
				}

				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
		}
		else
		{
			setAlertType("warning");
			setAlert("Passwords have to be non empty and the same.");
		}
	}

	return (
		<React.Fragment>
			{alert && <Alert severity={alertType} className={classes.alert}>{alert}</Alert>}
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<div>
					<TextField label="Password" type="password" value={pwd} onChange={event => setPwd(event.target.value)} className={classes.textField} variant="outlined" fullWidth={true}/>
					<TextField label="Password again" type="password" value={pwd2} onChange={event => setPwd2(event.target.value)} className={classes.textField} variant="outlined" fullWidth={true}/>
					<Box className={classes.btnBox}>
						<Button variant="contained" color="primary" size="large" type="submit">
							Change password
						</Button>
					</Box>
				</div>
			</form>
		</React.Fragment>
	);
}