import React from "react";
import BlockLabels from "../BlockLabels";
import BlockIntegration from "./BlockIntegration";

export default function MeasurementIntegration(props) {
	const measurements = props.client.measurements || [];
	const blocks = [];

	for (let i in measurements)
	{
		if (measurements.hasOwnProperty(i))
		{
			blocks.push(<BlockIntegration id={i} key={i}/>);
		}
	}

	return (
		<React.Fragment>
			<BlockLabels text1="Measurement integration" margin="32px 0 16px 32px"/>
			{blocks}
		</React.Fragment>
	);
}