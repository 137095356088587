import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import {
	commonUtils_AddClass,
	commonUtils_CheckAuthorized, commonUtils_ClearSelection, commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint,
	commonUtils_GetClient,
	commonUtils_GetData,
	commonUtils_GetUser, commonUtils_MeasurementUrl, commonUtils_RedirectTo, commonUtils_RemoveClass,
	commonUtils_SetData
} from "../utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";
import {Tooltip, withStyles} from "@material-ui/core";

const useStyles = makeStyles({
	root: props => ({
		padding: 40,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		margin: "0 auto",
		marginBottom: 32
	}),
	col1: {
		width: 763,
		float: "left",
		borderRight: "1px solid #DBE5EE",
		boxSizing: "border-box",
		"& h1": {
			margin: 0,
			marginBottom: 24
		},
		"& h5": {
			font: "normal normal normal 16px/25px Montserrat",
			letterSpacing: 0,
			color: "#000000",
			margin: 0,
			marginBottom: 9
		},
		"& .instruction": {
			display: "none",
			width: "90%",
			fontSize: 14,
			backgroundColor: "#F1F5F8",
			padding: "1px 24px 24px 24px",
			marginTop: 24,
			borderRadius: 16,
			"& p": {
				margin: 0,
				marginTop: 23
			}
		}
	},
	col2: {
		width: 355,
		float: "left",
		paddingLeft: 28,
		boxSizing: "border-box",
		"& .code-info": {
			"& .header": {
				paddingBottom: 32,
				borderBottom: "1px solid #DBE5EE",
				marginBottom: 24,
				"& .icon-bg": {
					background: "#DEE4E9 0% 0% no-repeat padding-box",
					borderRadius: 8,
					float: "left",
					marginRight: 16,
					"& .icon": {
						width: 22,
						height: 24,
						margin: "12px 13px",
						background: "transparent url('icon/integration.png') 0% 0% no-repeat padding-box",
					}
				},
				"& .name": {
					"& h1": {
						margin: 0
					}
				}
			},
			"& .info": {
				"& p": {
					margin: 0
				},
				"& .mt24": {
					marginTop: 24
				},
				"& button": {
					borderRadius: 8,
					boxShadow: "0px 4px 16px #022B5729",
					padding: "13px 32px"
				}
			}
		}
	},
	codes: {
		marginTop: 32,
		"& .col": {
			float: "left",
			width: "50%",
			"& .caption": {
				backgroundColor: "#F1F5F8",
				padding: 24,
				textAlign: "left",
				display: "block",
				border: "none",
				borderRadius: 16,
				width: 350,
				height: 190,
				boxSizing: "border-box",
				marginTop: 16,
				resize: "none",
				outline: 0
			},
			"& button": {
				font: "normal normal normal 13px/16px 'Montserrat'",
				color: "#000000",
				textTransform: "uppercase",
				boxShadow: "none",
				marginTop: 16
			},
			"& button.copied": {
				backgroundColor: "#00D789"
			}
		}
	}
});

export default function BlockIntegration(props) {
	const classes = useStyles(props);
	const measurement = commonUtils_GetClient().measurements[props.id].value;

	function copyCode(ev)
	{
		ev.preventDefault();

		let btn = ev.target;

		if (btn.tagName.toLowerCase() !== "button")
		{
			btn = btn.parentNode;
		}

		let codeInput = btn.previousSibling;

		codeInput.select();
		codeInput.setSelectionRange(0, 9999);

		document.execCommand("copy");

		commonUtils_ClearSelection();

		btn.childNodes[0].innerText = "Copied";
		commonUtils_AddClass(btn, "copied");

		window.setTimeout(function (){
			btn.childNodes[0].innerText = "Copy code";
			commonUtils_RemoveClass(btn, "copied");
		}, 1000);
	}

	function continueToResearch(ev, mid)
	{
		ev.preventDefault();

		layoutUtils_LoadingStart();

		const user = commonUtils_GetUser();
		const client = commonUtils_GetClient();

		fetch(
			commonUtils_GetApiEndpoint(process.env.REACT_APP_API_MEASUREMENT_IMPLEMENTED),
			commonUtils_GetApiCallOptions({uid: user.id, token: user.token, cid: client.id})
		)
		.then(blob => blob.json())
		.then(response => {
			if (commonUtils_CheckAuthorized(response))
			{
				if (response.statusCode === 200)
				{
					const data = commonUtils_GetData();
					data.client.is_measurement_implemented = true;

					commonUtils_SetData(data);

					commonUtils_RedirectTo(commonUtils_MeasurementUrl(mid));
				}
				else
				{
					console.log(response.statusMessage);
				}
			}

			layoutUtils_LoadingEnd();
		})
		.catch(e => {
			console.log(e);
			layoutUtils_LoadingEnd();
			return e;
		});
	}

	function showHideInstruction(ev)
	{
		ev.preventDefault();

		if (getComputedStyle(ev.target.nextSibling).display === "block")
		{
			ev.target.nextSibling.style.display = "none";
			ev.target.innerText = "Show instruction";
		}
		else
		{
			ev.target.nextSibling.style.display = "block";
			ev.target.innerText = "Hide instruction";
		}
	}

	return (
		<Card className={classes.root}>
			<div className={classes.col1}>
				<h1>Integrate with your business</h1>
				<p className="body1-black">To integrate the measurement, you need to upload these codes to your website.</p>
				<a href="#" className="body2-grey-link show-hide-instruction" onClick={showHideInstruction}>Show instruction</a>
				<div className="instruction">
					<p><b>{"Nasazení párovacího kódu"}</b>{" je nutné pro propojení návštěvníků webu a panelistů, na kterých bude probíhat dotazování."}</p>
					<p>{"Párovací kód je možné umístit přímo do těla stránky za <body>, případně využít třetí strany, například google tag manageru, kde vložíte jako vlastní značku HTML na všechny stránky."}</p>
					<p>{"Doporučujeme vložit i konverzní kód a to na děkovací stránku, tento kod bude v blízké budoucnosti umožňovat oddělení návštěvníků, kteří nakoupili od návštěvníků, kteří nenakoupili a filtrovat výsledky."}</p>
					<p>{"Kód je možné nasadit i na více URL, nicméně dojde ke spojení návštěvnosti."}</p>
					<p>{"Ukládání dat a jejich správa se řídí obchodními podmínkami."}</p>
				</div>

				<div className={classes.codes}>
					<div className="col">
						<span className="block-label">Visit code</span>
						<textarea className="caption" readOnly value={measurement.visitCode}> </textarea>
						<Button variant="contained" color="secondary" onClick={copyCode}>Copy code</Button>
					</div>
					<div className="col">
						<span className="block-label">Conversion code</span>
						<textarea className="caption" readOnly value={measurement.conversionCode}> </textarea>
						<Button variant="contained" color="secondary" onClick={copyCode}>Copy code</Button>
					</div>
					<div className="clearfix"> </div>
				</div>
			</div>
			<div className={classes.col2}>
				<div className="code-info">
					<div className="header">
						<div className="icon-bg">
							<div className="icon"> </div>
						</div>
						<div className="name">
							<span className="block-label">Measurement</span>
							<h1>{measurement.name}</h1>
						</div>
					</div>
					<div className="info">
						<span className="block-label">Measurement ID</span>
						<p className="body1-black">{measurement.id}</p>
						<span className="block-label mt24">Measurement ID</span>
						<p className="body1-black">{measurement.created}</p>
						<p className="body1-black mt24">If you have integrated, you can continue.</p>
						<Button variant="contained" color="primary" className="mt24" onClick={event => continueToResearch(event, props.id)}>Continue to research</Button>
					</div>
				</div>
			</div>
		</Card>
	);
}