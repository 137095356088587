import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {generateQuestionName} from "../PreviewUtils";
import {FormControlLabel, Radio} from "@material-ui/core";

const useStyles = makeStyles({
	answerOption: {
		float: "left",
		width: "33%"
	}
});

export default function RadioListPreview(props) {
	const classes = useStyles(props);
	const questionName = generateQuestionName(props);
	const radios = [];
	let numChecked = 0;

	for (let i in props.question.options.value)
	{
		let check = numChecked > 0 ? false : Math.random() < 0.3;
		numChecked += check ? 1 : 0;

		radios.push(
			<div className={classes.answerOption} key={i}><FormControlLabel control={<Radio disabled={true} checked={check} color="primary"/>} label={props.question.options.value[i]}/></div>
		);
	}

	return (
		<React.Fragment>
			<h5>Question preview</h5>
			<h6>{questionName}</h6>
			{radios}
		</React.Fragment>
	);
}