import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {generateQuestionName} from "../PreviewUtils";
import {commonUtils_GetApiBaseUrl} from "../utils/common.utils";

const useStyles = makeStyles({
	logo: {
		display: "inline-block",
		opacity: 0.7,
		"& div": {
			width: 160,
			display: "inline-block",
			margin: 0,
			marginRight: 32,
			border: "1px solid #DBE5EE",
			boxSizing: "border-box",
			padding: 8,
			borderRadius: 8,
			"& img": {
				width: "100%"
			},
		},
		"& div.active": {
			border: "3px solid #01448D",
		}
	},
});

export default function ImageCheckboxListPreview(props) {
	const classes = useStyles(props);
	const questionName = generateQuestionName(props);
	const squares = [];

	if (props.brandIndex)
	{
		for (let i in props.research.brands.value)
		{
			let active = Math.random() < 0.7;
			squares.push(<div key={i} className={classes.logo}><div className={active ? 'active' : ''}><img src={commonUtils_GetApiBaseUrl() + "/" + props.research.brands.value[i].value.resource.value.filePath}/></div></div>);
		}
	}
	else if (props.productIndex)
	{
		for (let i in props.research.products.value)
		{
			let active = Math.random() < 0.7;
			squares.push(<div key={i} className={classes.logo}><div className={active ? 'active' : ''}><img src={commonUtils_GetApiBaseUrl() + "/" + props.research.products.value[i].value.resource.value.filePath}/></div></div>);
		}
	}

	return (
		<React.Fragment>
			<h5>Question preview</h5>
			<h6>{questionName}</h6>
			{squares}
		</React.Fragment>
	);
}