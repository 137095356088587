import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import BlockLabels from "../BlockLabels";
import {Button, CircularProgress, FormControlLabel} from "@material-ui/core";
import CustomCheckbox from "../customElements/CustomCheckbox";
import {filterUtils_GetResultFilter} from "../utils/filter.utils";
import {commonUtils_GetData} from "../utils/common.utils";
import {researchUtils_ReloadResults, researchUtils_RenderResults} from "../utils/research.utils";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		padding: 32,
		borderRadius: 24,
		boxShadow: "0px 8px 24px #114E9114",
		position: "relative",
		"& >a": {
			textDecoration: "underline",
			font: "normal normal medium 15px/23px Raleway",
			color: "#5C7692",
			display: "block",
			position: "absolute",
			top: 32,
			right: 32
		},
		"& .btn-apply": {
			background: "#0162CA 0% 0% no-repeat padding-box",
			boxShadow: "0px 4px 16px #022B5729",
			borderRadius: 8,
			font: "normal normal normal 14px/18px Montserrat",
			color: "#FFFFFF",
			textTransform: "uppercase",
			padding: "16px 32px",
			position: "absolute",
			right: 32,
			bottom: 32
		},
		"& .btn-apply.loaded": {
			background: "#00D789 0% 0% no-repeat padding-box"
		}
	},
	row: {
		float: "left",
		width: "100%",
		marginBottom: 8,
		"& .col1": {
			width: 150,
			"& >div": {
				margin: 0,
				marginTop: 2
			}
		}
	},
	col: {
		float: "left",
		"& label": {
			width: 160,
			float: "left",
			"& span": {
				font: "normal normal medium 15px/23px Raleway"
			},
			"& .MuiCheckbox-root": {
				padding: "0 8px"
			}
		}
	},
	buttonProgress: {
		marginRight: 16,
		color: "#ffffff"
	}
});

export default function BlockResultFilter(props) {
	const classes = useStyles(props);
	const filters = [];
	const currentFilter = filterUtils_GetResultFilter();
	const filterDefinitions = commonUtils_GetData().socdem_filter;

	const [loading, setLoading] = useState(false);

	function chckChange()
	{
		updateFilterGroupSelection();
	}

	function reloadResults(e)
	{
		e.preventDefault();

		setLoading(true);

		researchUtils_ReloadResults(function (data){
			researchUtils_RenderResults(data);
			setLoading(false);
		});
	}

	function updateFilterGroupSelection()
	{
		const el = document.getElementById("filter_group_selection");
		const numChNotChecked = document.querySelectorAll(".filter-checkbox input[type='checkbox']:not(:checked)").length;

		el.innerText = numChNotChecked > 0 ? "Select all" : "Unselect all";
	}

	setTimeout(function (){
		updateFilterGroupSelection();
	}, 5000);

	function filterGroupSelectionClick(e)
	{
		e.preventDefault();

		const numChNotChecked = document.querySelectorAll(".filter-checkbox input[type='checkbox']:not(:checked)").length;

		const chcks = document.querySelectorAll(numChNotChecked > 0 ? ".filter-checkbox input[type='checkbox']:not(:checked)" : ".filter-checkbox input[type='checkbox']:checked");

		for (let i in chcks)
		{
			if (chcks.hasOwnProperty(i))
			{
				chcks[i].click();
			}
		}

		updateFilterGroupSelection();
	}

	for (let i in filterDefinitions)
	{
		if (filterDefinitions.hasOwnProperty(i))
		{
			let f = filterDefinitions[i];
			let controls = [];

			for (let j in f.options)
			{
				controls.push(
					<FormControlLabel
						className={classes.checkboxLabel}
						key={f.socdem_key + "_" + j}
						control={
							<CustomCheckbox
								onClick={chckChange}
								value={f.options[j].values.join(",")}
								defaultChecked={Object.keys(currentFilter).length === 0 || (currentFilter[f.socdem_key] || []).indexOf(f.options[j].values.join(",")) > -1}
								name={f.socdem_key + "[]"}
								className="filter-checkbox"
							/>}
						label={j}
					/>);
			}

			filters.push(
				<div className={classes.row} key={"filter_" + i}>
					<div className={classes.col + " col1"}>
						<BlockLabels text1={f.name} margin="0 0 16px 0"/>
					</div>
					<div className={classes.col}>
						{controls}
					</div>
				</div>
			);
		}
	}

	return (
		<Card className={classes.root} id="resultFilter">
			{filters}
			<a href="#" id="filter_group_selection" onClick={filterGroupSelectionClick}> </a>
			<Button type="button" className={clsx(["btn-apply"])} onClick={reloadResults}>
				{loading && <CircularProgress size={16} className={classes.buttonProgress} />}
				Apply filter
			</Button>
		</Card>
	);
}
