import {commonUtils_Webalize} from "./common.utils";
import {tableUtils_GetColIndexByHeaderValue, tableUtils_GetData, tableUtils_GetRowIndexByValue} from "./table.utils";

export function chartUtils_ApplyStyles(chart, qid)
{
    chart.palette(window.anychart.palettes.blue);
    chart.animation(true);
    chart.background().fill("#fff", 1);
    chart.background().stroke("#fff");

    chart.tooltip().background().fill("#333 0.8");
    chart.tooltip().background().stroke("#333");
    chart.tooltip().background().corners(5);

    let menu = chart.contextMenu();

    menu.itemsFormatter(function(items){
        items = {};

        let researchName = document.querySelector("h1").innerText;
        let question = document.getElementById("QRD_" + qid).parentNode.querySelector(".question").innerText;

        let fileName = 'chart_' + commonUtils_Webalize(researchName) + '_' + commonUtils_Webalize(question);

        items['save-as-image'] = {
            text: 'Save as IMAGE',
            action: function() {
                chart.saveAsPng({quality: 0.3, filename: fileName});
            },
            index: 1
        };

        return items;
    });
}

export function chartUtils_GetDummyDataImageCheckboxList(question, resources)
{
    let i, data = {rows: {}}, value;

    for (i in resources)
    {
        value = 100 / (Math.random() + 1)
        data.rows[resources[i].value.id] = [resources[i].value.name, value, -(100 - value)];
    }

    return data;
}

export function chartUtils_GetDummyDataCheckboxList(question, resources)
{
    let i, j, k, data = {rows: [{}, {}]}, values = [], sum = 0;

    const options = question.options.value;

    for (i in resources)
    {
        values = [];
        sum = 0;

        for (j in options)
        {
            values.push(Math.random() + 1);
            sum += values[values.length - 1];
        }

        k = -1;
        for (j in options)
        {
            k++;

            data.rows[0][options[j]] = data.rows[0][options[j]] || {};
            data.rows[0][options[j]][resources[i].value.id] = 100 * values[k] / sum;
        }

        data.rows[1][resources[i].value.id] = {
            id: resources[i].value.id,
            name: resources[i].value.name,
            filepath: "/" + resources[i].value.resource.value.filepath
        };
    }

    return data;
}

export function chartUtils_GetDummyDataSliderList(question, resources)
{
    let i, j, k, data = {rows: [{}, {}], sliders: question.settings.value.Sliders};

    for (i in resources)
    {
        for (j in data.sliders)
        {
            for (k = question.settings.value.MinValue; k <= question.settings.value.MaxValue; k++)
            {
                if (k !== 0)
                {
                    data.rows[0][resources[i].value.id] = data.rows[0][resources[i].value.id] || {};
                    data.rows[0][resources[i].value.id][j] = data.rows[0][resources[i].value.id][j] || {};
                    data.rows[0][resources[i].value.id][j][k] = Math.ceil(10 * Math.random() + 1);
                }
            }
        }

        data.rows[1][resources[i].value.id] = {
            id: resources[i].value.id,
            name: resources[i].value.name,
            filepath: "/" + resources[i].value.resource.value.filepath
        };
    }

    return data;
}

export function chartUtils_GetDummyDataRadioList(question, resources)
{
    let i, j, data = {rows: {}, sum: {}};

    for (i in resources)
    {
        data.rows[resources[i].value.name] = data.rows[resources[i].value.name] || [];

        for (j in question.options.value)
        {
            data.rows[resources[i].value.name].push([question.options.value[j], Math.ceil(10 * Math.random() + 1)]);
        }
    }

    return data;
}

export function chartUtils_GetDummyDataRadioListNegativeBar(question, resources)
{
    let i, j, data = {rows: {}, max: {}, sum: {}}, options = ["Ano", "Ne"];

    for (i in resources)
    {
        data.rows[resources[i].value.name] = data.rows[resources[i].value.name] || [];

        for (j in options)
        {
            data.rows[resources[i].value.name].push([options[j], Math.ceil(10 * Math.random() + 1)]);
        }
    }

    return data;
}

export function chartUtils_GetDummyDataTagCloud(question, resources)
{
    let i, j, k, data = {rows: [], sum: {}, max: {}}, value;
    let words = "Lorem ipsum dolor sit amet consectetuer adipiscing elit Donec quis nibh at felis congue commodo".toLowerCase().split(" ");

    k = -1;
    for (i in resources)
    {
        k++;

        for (j in words)
        {
            value = Math.ceil(10 * Math.random() + 1);

            data.sum[resources[i].value.id] = data.sum[resources[i].value.id] || 0;
            data.sum[resources[i].value.id] += value;

            data.rows[k] = data.rows[k] || "";
            data.rows[k] += (j > 0 ? "\n" : "") + words[j] + "," + value + "," + resources[i].value.name;
        }
    }

    return data;
}

export function chartUtils_SetupChartHover(chart, table)
{
    chart.listen("pointMouseOver", function(ev) {
        const resourceName = ev.point.W.de.x;
        const seriesName = ev.point.W.kr;
        const rowIndex = parseInt(tableUtils_GetRowIndexByValue(table, resourceName, 0));
        const colIndex = parseInt(tableUtils_GetColIndexByHeaderValue(table, seriesName));
        const tableData = tableUtils_GetData(table);
        const rowsNum = tableData.length;
        const colsNum = tableData[0].length;
        let i, j;

        for (i = 0; i < rowsNum; i++)
        {
            for (j = 0; j < colsNum; j++)
            {
                if (i !== rowIndex && j !== colIndex)
                {
                    table.getCell(i, j).fill("#fff", 1);
                }
                else
                {
                    table.getCell(i, j).fill("#f2f2f2", 1);
                }
            }
        }
    });
}