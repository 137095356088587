import React, {useState} from "react";
import BlockLabels from "../BlockLabels";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import {Button, TextareaAutosize} from "@material-ui/core";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";
import {
	commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint,
	commonUtils_GetClient,
	commonUtils_GetUser
} from "../utils/common.utils";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles({
	root: props => ({
		padding: 40,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		margin: "0 auto",
		marginBottom: 32,
		"& .form-50p": {
			width: "50%",
			"& button": {
				float: "right"
			}
		}
	}),
	input: {
		marginRight: 24
	},
	blockInput: {
		display: "block",
		marginBottom: 32
	},
	btn: {
		padding: "8px 24px"
	},
	alert: {
		marginBottom: 24,
		borderRadius: 8
	}
});

export default function UserProfile(props) {
	const classes = useStyles(props);

	const user = commonUtils_GetUser();
	const client = commonUtils_GetClient();

	const [pw1, setPw1] = useState("");
	const [pw2, setPw2] = useState("");
	const [alertPwd, setAlertPwd] = useState("");
	const [alertPwdSeverity, setAlertPwdSeverity] = useState("info");

	const [cin, setCin] = useState(client.billingData.cin || "");
	const [email, setEmail] = useState(client.billingData.email || user.email || "");
	const [phone, setPhone] = useState(client.billingData.phone || "");
	const [company, setCompany] = useState(client.billingData.company || "");
	const [vatNumber, setVatNumber] = useState(client.billingData.vatNumber || "");
	const [address, setAddress] = useState(client.billingData.billingAddress || "");
	const [alertBill, setAlertBill] = useState("");
	const [alertBillSeverity, setAlertBillSeverity] = useState("info");



	function handleSubmitChangePassword(event)
	{
		event.preventDefault();

		if (pw1 && pw2 && pw1 === pw2)
		{
			layoutUtils_LoadingStart();

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_PASSWORD_CHANGE),
				commonUtils_GetApiCallOptions({uid: user.id, token: user.token, p: pw1})
			)
				.then(blob => blob.json())
				.then(response => {
					if (response.statusCode === 200)
					{
						setAlertPwd("Password changed successfully.");
						setAlertPwdSeverity("success");
						setPw1("");
						setPw2("");
					}
					else
					{
						setAlertPwd("Password change failed, please try again later.");
						setAlertPwdSeverity("warning");
					}

					layoutUtils_LoadingEnd();
				})
				.catch(e => {
					console.log(e);
					layoutUtils_LoadingEnd();
					return e;
				});
		}
		else
		{
			setAlertPwd("Passwords have to be filled in and have to be the same.");
			setAlertPwdSeverity("warning");
		}
	}

	function handleSubmitBillingData(event)
	{
		event.preventDefault();

		layoutUtils_LoadingStart();

		const d = {
			cin: cin, email: email, phone: phone, company: company, vatNumber: vatNumber, billingAddress: address
		}

		fetch(
			commonUtils_GetApiEndpoint(process.env.REACT_APP_API_SET_BILLING_DATA),
			commonUtils_GetApiCallOptions({uid: user.id, token: user.token, cid: client.id, d: d})
		)
			.then(blob => blob.json())
			.then(response => {
				if (response.statusCode === 200)
				{
					setAlertBill("Billing data saved successfully.");
					setAlertBillSeverity("success");
				}
				else if (response.statusCode < 500)
				{
					setAlertBill(response.data.detail);
					setAlertBillSeverity("warning");
				}
				else
				{
					setAlertBill("Billing data saving failed, please try again later.");
					setAlertPwdSeverity("warning");
				}

				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
	}

	return (
		<React.Fragment>
			<BlockLabels text1="Change password" margin="32px 0 16px 32px"/>
			<Card className={classes.root}>
				{alertPwd && <Alert severity={alertPwdSeverity} className={classes.alert}>{alertPwd}</Alert>}
				<form noValidate autoComplete="off" onSubmit={handleSubmitChangePassword}>
					<TextField label="New password" type="password" size="small" className={classes.input} value={pw1} onChange={event => setPw1(event.target.value)} variant="outlined"/>
					<TextField label="New password again" type="password" size="small" className={classes.input} value={pw2} onChange={event => setPw2(event.target.value)} variant="outlined"/>
					<Button variant="contained" color="primary" size="medium" type="submit" className={classes.btn}>Change password</Button>
				</form>
			</Card>

			<BlockLabels text1="Billing data" margin="32px 0 16px 32px"/>
			<Card className={classes.root}>
				{alertBill && <Alert severity={alertBillSeverity} className={classes.alert}>{alertBill}</Alert>}
				<form noValidate autoComplete="off" onSubmit={handleSubmitBillingData} className="form-50p">
					<TextField label="Company" type="text" size="small" fullWidth={true} value={company} className={classes.blockInput} onChange={event => setCompany(event.target.value)} variant="outlined"/>
					<TextField label="E-mail" type="text" size="small" fullWidth={true} value={email} className={classes.blockInput} onChange={event => setEmail(event.target.value)} variant="outlined"/>
					<TextField label="Phone" type="text" size="small" fullWidth={true} value={phone} className={classes.blockInput} onChange={event => setPhone(event.target.value)} variant="outlined"/>
					<TextField label="Billing address" type="text" size="small" fullWidth={true} value={address} className={classes.blockInput} onChange={event => setAddress(event.target.value)} variant="outlined"/>
					<TextField label="Company ID" type="text" size="small" fullWidth={true} value={cin} className={classes.blockInput} onChange={event => setCin(event.target.value)} variant="outlined"/>
					<TextField label="VAT number" type="text" size="small" fullWidth={true} value={vatNumber} className={classes.blockInput} onChange={event => setVatNumber(event.target.value)} variant="outlined"/>
					<Button variant="contained" color="primary" size="medium" type="submit" className={classes.btn}>Save</Button>
				</form>
			</Card>
		</React.Fragment>
	);
}