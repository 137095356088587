import React from "react";
import BlockLabels from "../BlockLabels";
import BlockBrandSettings from "./BlockBrandSettings";
import BlockRespondents from "./BlockRespondents";
import BlockQuestion from "./BlockQuestion";
import BlockAdditionalQuestion from "./BlockAdditionalQuestion";
import BlockProductSettings from "./BlockProductSettings";

export default function ResearchDefinition(props) {

	const questions = props.research.questionPatterns.value;
	const defaultQuestions = [];
	const addQuestions = [];
	let resourcesBlock;

	for (let i in questions)
	{
		if (questions.hasOwnProperty(i))
		{
			if (questions[i].value.isDefault.value)
			{
				defaultQuestions.push(<BlockQuestion
					question={questions[i].value}
					client={props.client}
					measurement={props.measurement}
					research={props.research}
					key={i}
					setIsLoading={props.setIsLoading}/>);
			}
			else
			{
				addQuestions.push(<BlockAdditionalQuestion
					question={questions[i].value}
					client={props.client}
					measurement={props.measurement}
					research={props.research}
					key={i}
					handleAddQuestionSelectionChange={props.handleAddQuestionSelectionChange}
					setIsLoading={props.setIsLoading}/>);
			}
		}
	}

	if (props.measurement.researches[props.research.id.value].type === "App.Model.Research.ProductResearch")
	{
		resourcesBlock = (
			<React.Fragment>
				<BlockLabels text1="Product settings" margin="32px 0 16px 32px"/>
				<BlockProductSettings
					client={props.client}
					measurement={props.measurement}
					research={props.research}
					isEditable={true}
					handleFileSelected={props.handleFileSelected}
					handleResourceChange={props.handleResourceChange}
					setIsError={props.setIsError}
					setErrorMessage={props.setErrorMessage}
				/>
			</React.Fragment>);
	}
	else
	{
		resourcesBlock = (
			<React.Fragment>
				<BlockLabels text1="Brand settings" margin="32px 0 16px 32px"/>
				<BlockBrandSettings
					client={props.client}
					measurement={props.measurement}
					research={props.research}
					isEditable={true}
					handleFileSelected={props.handleFileSelected}
					handleResourceChange={props.handleResourceChange}
					setIsError={props.setIsError}
					setErrorMessage={props.setErrorMessage}
				/>
			</React.Fragment>);
	}

	return (
		<React.Fragment>
			{resourcesBlock}
			<div className="clearfix"> </div>

			<BlockLabels text1="Respondents" mr1="0" margin="32px 0 16px 32px"/>
			<BlockRespondents client={props.client}
							  measurement={props.measurement}
							  research={props.research}
							  handleNumRespondentsChange={props.handleNumRespondentsChange}/>
			<div className="clearfix"> </div>

			<BlockLabels text1="Required questions included in the base package"
						 text2="Preview"
						 text3="Price"
						 mr1="0"
						 mr2="940"
						 mr3="1090"
						 margin="32px 0 0 32px"/>
			{defaultQuestions}
			<div className="clearfix"> </div>

			<BlockLabels text1="Additional questions" margin="32px 0 0 32px"/>
			{addQuestions}
			<div className="clearfix mb64"> </div>
		</React.Fragment>
	);
}