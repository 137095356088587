import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import BlockLabels from "../BlockLabels";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {FormControl, FormHelperText} from "@material-ui/core";
import {
	commonUtils_CheckAuthorized, commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint,
	commonUtils_GetLocalization,
	commonUtils_GetUser, commonUtils_NewResearchUrl, commonUtils_RedirectTo, commonUtils_SetData
} from "../utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";

const useStyles = makeStyles({
	root: props => ({
		padding: 40,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: 400,
		boxSizing: "border-box",
		float: "left",
		marginRight: 32
	}),
	col1: {
		width: "100%",
		float: "left",
		boxSizing: "border-box",
		"& .header": {
			paddingBottom: 32,
			borderBottom: "1px solid #DBE5EE",
			marginBottom: 24,
			"& .icon-bg": {
				background: "#DEE4E9 0% 0% no-repeat padding-box",
				borderRadius: 8,
				float: "left",
				marginRight: 16,
				"& .icon": {
					width: 24,
					height: 24,
					margin: 12,
					background: "transparent url('/icon/circle-plus.png') 0% 0% no-repeat padding-box",
				}
			},
			"& .heading": {
				"& h1": {
					margin: 0,
					height: 48,
					lineHeight: 2
				}
			}
		},
		"& form": {
			"& button": {
				borderRadius: 8,
				boxShadow: "0px 4px 16px #022B5729",
				padding: "13px 32px",
			}
		}
	}
});

export default function BlockNewResearchForm(props) {
	const classes = useStyles(props);

	const [name, setName] = useState("");
	const [type, setType] = useState("0");
	const [isNewResearchNameValid, setIsNewResearchNameValid] = useState(true);
	const [isNewResearchTypeValid, setIsNewResearchTypeValid] = useState(true);

	const user = commonUtils_GetUser();
	const localization = commonUtils_GetLocalization();

	function checkNewResearchName()
	{
		const isValid = name.trim() !== '';

		setIsNewResearchNameValid(isValid);

		return isValid;
	}

	function checkNewResearchType()
	{
		const isValid = type === "0" || type === "1";

		setIsNewResearchTypeValid(isValid);

		return isValid;
	}

	function isNewResearchValid()
	{
		return checkNewResearchName() && checkNewResearchType();
	}

	function handleSubmit(event)
	{
		event.preventDefault();

		if (isNewResearchValid())
		{
			layoutUtils_LoadingStart();

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_NEW),
				commonUtils_GetApiCallOptions({
					uid: user.id,
					token: user.token,
					co: localization.country,
					cid: props.client.id,
					mid: props.measurement.id,
					name: name,
					type: type
				})
			)
			.then(blob => blob.json())
			.then(response => {
				if (commonUtils_CheckAuthorized(response))
				{
					if (response.statusCode === 200)
					{
						commonUtils_SetData(response.data);
						commonUtils_RedirectTo(commonUtils_NewResearchUrl(props.measurement.id, response.data.id));
					}
				}

				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
		}
	}

	return (
		<Card className={classes.root}>
			<div className={classes.col1}>
				<div className="header">
					<div className="icon-bg">
						<div className="icon"> </div>
					</div>
					<div className="heading">
						<h1>Create new</h1>
					</div>
					<div className="clearfix"> </div>
				</div>
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<BlockLabels text1="Research information" margin="0 0 24px 0"/>
					<TextField
						label="Research name"
						type="text"
						error={!isNewResearchNameValid}
						helperText={isNewResearchNameValid ? '' : 'Enter research name, please.'}
						value={name}
						onChange={e => setName(e.target.value)}
						variant="outlined"
						fullWidth={true}
					/>
					<BlockLabels text1="Research type" margin="32px 0 24px 0"/>
					<FormControl error={!isNewResearchTypeValid}>
						<RadioGroup value={type} onChange={e => setType(e.target.value.trim())}>
							<FormControlLabel control={<Radio value="0" color="primary" />} label="Brand research" />
							{/*<FormControlLabel control={<Radio value="1" color="primary" />} label="Product research" />*/}
						</RadioGroup>
						{!isNewResearchTypeValid && <FormHelperText>Type of the research is not selected</FormHelperText>}
					</FormControl>
					<div className="mt32">
						<Button variant="contained" color="primary" type="submit">Create new research</Button>
					</div>
				</form>
			</div>
		</Card>
	);
}