import {commonUtils_Webalize} from "./common.utils";

export function tableUtils_ApplyStyles(table, qid)
{
    table.cellBorder("#B8B8B8");
    table.vAlign("middle");
    table.hAlign("center");
    table.fontSize(10);
    table.fontColor('#000000');

    table.cellFill("#fff", 1);

    let menu = table.contextMenu();

    menu.itemsFormatter(function(items){
        items = {};

        let researchName = document.querySelector("h1").innerText;
        let question = document.getElementById("QRD_" + qid).parentNode.querySelector(".question").innerText;

        let fileName = 'table_' + commonUtils_Webalize(researchName) + '_' + commonUtils_Webalize(question);

        items['save-as-xlsx'] = {
            text: 'Save as XLSX',
            action: function() {
                table.saveAsXlsx(fileName);
            },
            index: 1
        };

        return items;
    });
}

/**
 * @param table anychart.standalones.table
 * @param value string search value
 * @param searchCol index of the column, where search the value
 * @return int|null
 */
export function tableUtils_GetRowIndexByValue(table, value, searchCol)
{
    let i;
    const data = tableUtils_GetData(table);

    for (i in data)
    {
        if (data.hasOwnProperty(i))
        {
            if ((data[i][searchCol] || '') === value)
            {
                return i;
            }
        }
    }

    return null;
}

/**
 * @param table anychart.standalones.table
 * @param value string search value
 * @return int|null
 */
export function tableUtils_GetColIndexByHeaderValue(table, value)
{
    let i;
    const data = tableUtils_GetData(table);

    for (i in data[0])
    {
        if (data[0].hasOwnProperty(i))
        {
            if (data[0][i] === value)
            {
                return i;
            }
        }
    }

    return null;
}

/**
 * @param table anychart.standalones.table
 * @return array
 */
export function tableUtils_GetData(table)
{
    let arr = table.toCsv({columnsSeparator: "::", rowsSeparator: "**"}).split("**");
    let i;

    for (i in arr)
    {
        arr[i] = arr[i].split('::');
    }

    return arr;
}

/**
 * @param table anychart.standalones.table
 * @return int
 */
export function tableUtils_GetDataRowsNum(table)
{
    return table.toCsv({rowsSeparator: "**"}).split("**").length;
}
